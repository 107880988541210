import { forwardRef, useImperativeHandle, useEffect, useState } from 'react';

import { validateAnnee } from '../utils/champsFormat';

const AutoCompleteDropdownAnnee = ({ id, value, onChange, onFocus, options = [], className, decennie, setDecennie }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [displayValue, setDisplayValue] = useState('');

    useEffect(() => {
        if (options.includes(value)) {
            setDisplayValue(value);
        } else {
            setDisplayValue('');
        }
    }, [value, options]);

    const handleSelect = (option) => {
        setDisplayValue(option);
        onChange(option);
        setIsOpen(false);

		// Auto update decennie based on annee if decennie is currently empty
		if (!decennie) {
			const yearRegex = /^\d{4}$/;
			if (yearRegex.test(option)) {
				const firstThreeDigits = option.slice(0, 3); // Extract the first three digits of the year
				let newDecennie = `${firstThreeDigits}0`; // Add a '0' at the end to form the decade
				newDecennie = parseInt(newDecennie, 10)
				setDecennie(newDecennie); // Update the decennie state with the new value
			}
		}
	};

    const handleInputChange = (event) => {
        const userInput = event.target.value;
        setDisplayValue(userInput);
        const filtered = options.filter(option =>
            option.includes(userInput)
        );
        setFilteredOptions(filtered);
        setIsOpen(true);
    };

    const handleBlur = () => {
        if (!options.includes(displayValue)) {
            setDisplayValue('');
            onChange('');
        }
        setIsOpen(false);
    };

    const handleFocus = (e) => {
        if (options.length === 0) {
            onFocus(e);
        }
    };

    return (
        <div className="custom-dropdown">
            <input
                id={id}
                value={displayValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                maxLength="9"
				className={className}
            />
            {isOpen && filteredOptions.length > 0 && (
                <ul className="custom-dropdown-list">
                    {filteredOptions.map((option, index) => (
                        <li
                            key={index}
                            onMouseDown={() => handleSelect(option)}
                            className="custom-dropdown-item"
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export const ChampAnnee = forwardRef(({ id, label = "Année", value, onChange, options = [], decennie, setDecennie }, ref) => {
	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	// options = Array de String
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
	}

	const validate = () => {
		let validation = validateAnnee(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	// Filter annee options based on decennie
	const [filteredOptions, setFilteredOptions] = useState(options);
	useEffect(() => {
        if (decennie) {
            // Filter the annees to only include those starting with the first 3 digits of decennie
            const decennieString = (decennie / 10).toString();
        	const filtered = options.filter(annee => 
            	annee.startsWith(decennieString)
        	);
            setFilteredOptions(filtered);
        } else {
            // If no decennie is selected, reset to all options
            setFilteredOptions(options);
        }
    }, [decennie, options]);

	const handleFocus = (e) => {
		setError(false);
	}

    return (<div className="form-group">
		<label htmlFor={id}>{label}</label>
        <AutoCompleteDropdownAnnee
			id={id}
			value={value}
			onChange={onChange}
			onFocus={handleFocus}
			options={filteredOptions}
			className={error ? "error" : ""}
			decennie={decennie}
			setDecennie={setDecennie}
		/>
    </div>);
});