export const getAdminLink = (link) => {
    const currentDomain = window.location.hostname;
    const currentPort = window.location.port;

    let adminDomain;
    let adminPort = currentPort;

    if (process.env.NODE_ENV === 'production') {
        // Replace www with admin in production
        adminDomain = currentDomain.replace('www.', 'admin.');
        adminPort = ''; // No port needed in production for HTTPS
    } else {
        // Handle development environment
        if (!currentDomain.startsWith('admin.')) {
            adminDomain = `admin.${currentDomain}`;
            if (currentPort === '3000') {
                adminPort = '3001';
            }
        } else {
            adminDomain = currentDomain;
        }
    }

    // Construct the full admin link
    const portPart = adminPort ? `:${adminPort}` : '';
    return `${window.location.protocol}//${adminDomain}${portPart}${link}`;
}

export const getPublicLink = (link) => {
    const currentDomain = window.location.hostname;
    const currentPort = window.location.port;

    let publicDomain;
    let publicPort = currentPort;

    if (process.env.NODE_ENV === 'production') {
        // Replace admin with www in production
        publicDomain = currentDomain.replace('admin.', 'www.');
        publicPort = ''; // No port needed in production for HTTPS
    } else {
        // Handle development environment
        if (currentDomain.startsWith('admin.')) {
            publicDomain = currentDomain.replace('admin.', '');
            if (currentPort === '3001') {
                publicPort = '3000';
            }
        } else {
            publicDomain = currentDomain;
        }
    }

    // Construct the full public link
    const portPart = publicPort ? `:${publicPort}` : '';
    return `${window.location.protocol}//${publicDomain}${portPart}${link}`;
}

export const sanitizeFileName = (name) => {
    return name
        .normalize("NFD")  // Normalize to decompose accented characters
        .replace(/[\u0300-\u036f]/g, "")  // Remove accents
        .replace(/[^a-zA-Z0-9\-_ ]/g, "")  // Remove all non-alphanumeric except hyphens, underscores, and spaces
        .trim();  // Trim spaces from the start and end
};

// Function to scroll to the top of the page smoothly
export const scrollToTop = () => {
	// requestAnimationFrame ensures the function is called after DOM updates
	window.requestAnimationFrame(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
	  	});
	});
};