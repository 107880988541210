import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoCheckmark, IoClose, IoEye, IoEyeOff } from 'react-icons/io5';

import { useAuthContext, api } from '@client/shared';
import '@client/shared/src/stylesheets/form.css';

import MetaTags from './MetaTags';
import DeleteAccountModal from './DeleteAccountModal'; // Import the account deletion confirmation modal
import useLocalizedPath from '../hooks/useLocalizedPath';

import '../stylesheets/account.css';

function AccountPage() {
	const { t } = useTranslation(['account', 'user_form']);
	const location = useLocation();
	const navigate = useNavigate();
	const { user, dispatch } = useAuthContext();
	const redirect_link = useLocalizedPath("/login");
	const delete_link = useLocalizedPath("/");

	// Protect page from visitors
    useLayoutEffect(() => {
        if (!user) {
			navigate(redirect_link);
		}
		// eslint-disable-next-line
    }, [user, location.pathname, navigate]);

	// Form Profile
	const [userProfile, setUserProfile] = useState(null);
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
	const [partner, setPartner] = useState(null);
	const [errorsProfile, setErrorsProfile] = useState({});
	const [serverErrorProfile, setServerErrorProfile] = useState('');
	const [profileSuccess, setProfileSuccess] = useState(false);

	// Fetch user data
	useEffect(() => {
		const fetchUserProfile = async () => {
			try {
				const response = await api.get(`/users/${user.user_id}`);
				if (response.status === 200) {
					setUserProfile(response.data.profile); // Set user profile
                	setEmail(response.data.profile.email || ''); // Set email state
				}
			} catch (error) {
				setUserProfile(null); // Clear profile on error
				console.log("Error while retrieving user profile");
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
			}
		};
	
		if (user) {
			fetchUserProfile();
		}
	},[user])

	// Update user data
	useEffect(() => {
		if (userProfile) {
			setFirstName(userProfile.first_name);
			setLastName(userProfile.last_name);
			setPartner(userProfile.partner_name);
		}
	},[userProfile, t])

	// Form Password
	const [currentPassword, setCurrentPassword] = useState('');  // current_password
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordCriteria, setPasswordCriteria] = useState({
        length: false,
        letter: false,
        number: false,
        specialChar: false,
        match: false
    });
	const [errorsPassword, setErrorsPassword] = useState({});
	const [serverErrorPassword, setServerErrorPassword] = useState('');
    const [currentPasswordIncorrect, setCurrentPasswordIncorrect] = useState(false);
	const [passwordSuccess, setPasswordSuccess] = useState(false);

	// Form Delete
	const [password, setPassword] = useState('');
	const [errorDelete, setErrorDelete] = useState('');
	const [serverErrorDelete, setServerErrorDelete] = useState('');
	const [passwordIncorrect, setPasswordIncorrect] = useState(false);

	// Show/Hide password state
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	// State to control the delete confirmation modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

	const validateFirstName = (value) => {
        if (!value) {
            return t('error.first_name_empty', { ns: 'user_form' });
        } else if (!value.match(/^[a-zA-ZÀ-ÿ'-]+$/)) {
            return t('error.first_name_format', { ns: 'user_form' });
        }
        return '';
    };

    const validateLastName = (value) => {
        if (value && !value.match(/^[a-zA-ZÀ-ÿ'-]*$/)) {
            return t('error.last_name_format', { ns: 'user_form' });
        }
        return '';
    };

	const validatePassword = (value) => {
        if (!value) {
            return t('error.password_empty', { ns: 'user_form' });
		}
        return '';
    };

    const checkPasswordCriteria = (value) => {
		const criteria = {
			length: value.length >= 10 && value.length <= 40,
			letter: /[a-zA-Z]/.test(value),
			number: /[0-9]/.test(value),
			specialChar: /[!@#$%^&*()_+{}[\]:;"'<>,.?~`|\\]/.test(value),
		};
		setPasswordCriteria(criteria);
		return criteria;
	};
	
	const validateNewPassword = (value) => {
		const criteria = checkPasswordCriteria(value);
		if (Object.values(criteria).includes(false)) {
			return t('error.password_format', { ns: 'user_form' });
		}
		return '';
	};
	

    const validateConfirmPassword = (value) => {
        if (value !== newPassword) {
            return t('error.password_mismatch', { ns: 'user_form' });
        }
        return '';
    };

	const handleChangeProfile = (e) => {
        const { id, value } = e.target;

        switch (id) {
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            default:
                break;
		}
    };

	const handleChangePassword = (e) => {
        const { id, value } = e.target;

        switch (id) {
            case 'currentPassword':
                setCurrentPassword(value);
                setCurrentPasswordIncorrect(false);	// Reset conflict state on change
                break;
            case 'newPassword':
                setNewPassword(value);
                validateNewPassword(value);  // Validate password criteria on change
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

	const handleChangeDelete = (e) => {
        const { id, value } = e.target;

		if (id === 'password') {
			setPassword(value);
            setPasswordIncorrect(false);	// Reset conflict state on change
		}
    };

	const handleBlurProfile = (e) => {
		const { id, value } = e.target;
        let error = '';

		switch (id) {
            case 'firstName':
                error = validateFirstName(value);
                setErrorsProfile(prev => ({ ...prev, [id]: error }));
                break;
            case 'lastName':
                error = validateLastName(value);
                setErrorsProfile(prev => ({ ...prev, [id]: error }));
                break;
            default:
                break;
        }
	};

	const handleBlurPassword = (e) => {
        const { id, value } = e.target;
        let error = '';

        switch (id) {
            case 'currentPassword':
                error = validatePassword(value);
                setErrorsPassword(prev => ({ ...prev, [id]: error }));
                break;
            case 'newPassword':
                error = validateNewPassword(value);
                setErrorsPassword(prev => ({ ...prev, [id]: error }));
                break;
            case 'confirmPassword':
                error = validateConfirmPassword(value);
                setErrorsPassword(prev => ({ ...prev, [id]: error }));
                break;
            default:
                break;
        }
    };

	const handleBlurDelete = (e) => {
        const { id, value } = e.target;
        
		if (id === 'password') {
			const error = validatePassword(value);
            setErrorDelete(error);
		}
    };

	const handleFocusProfile = (e) => {
		// Reset fields error on focus
        const { id } = e.target;
        setErrorsProfile(prev => ({ ...prev, [id]: '' }));
    };

    const handleFocusPassword = (e) => {
		// Reset fields error on focus
        const { id } = e.target;
        setErrorsPassword(prev => ({ ...prev, [id]: '' }));
    };

	const handleFocusDelete = (e) => {
		// Reset fields error on focus
        const { id } = e.target;
        if (id === 'password') {
			setErrorDelete('');
		}
    };

	const handleSubmitProfile = async (event) => {
		event.preventDefault();
		setServerErrorProfile('');
		setProfileSuccess(false);
        // Revalidate all fields on submit to capture any changes
        const newErrors = {
            firstName: validateFirstName(firstName),
            lastName: validateLastName(lastName)
        };

		setErrorsProfile(newErrors);

        if (Object.values(newErrors).some(error => error)) return; // Prevent form submission if any error exists

		// Prevent form submission if the values haven't changed
		if (firstName === userProfile.first_name && lastName === userProfile.last_name) {
			setServerErrorProfile( t('profile.no_change', { ns: 'account' }) );
			return;
		}

		try {
            const response = await api.patch(`/users/${user.user_id}`, {
                first_name: firstName.trim(),
                last_name: lastName.trim()
            });
            if (response.status === 200) {
				setUserProfile(response.data.profile);
				setProfileSuccess(true);
            }
        } catch (error) {
            setServerErrorProfile( t('profile.server_error', { ns: 'account' }) );
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
        }
	};

	const handleSubmitPassword = async (event) => {
		event.preventDefault();
		setServerErrorPassword('');
		setPasswordSuccess(false);
        // Revalidate all fields on submit to capture any changes
        const newErrors = {
            currentPassword: validatePassword(currentPassword),
            newPassword: validateNewPassword(newPassword),
            confirmPassword: validateConfirmPassword(confirmPassword)
        };

		setErrorsPassword(newErrors);

        if (Object.values(newErrors).some(error => error)) return; // Prevent form submission if any error exists

		// Prevent form submission if the password isn't changed
		if (currentPassword === newPassword) {
			setServerErrorPassword( t('password.no_change', { ns: 'account' }) );
			return;
		}

		try {
            const response = await api.patch(`/users/${user.user_id}/password`, {
                current_password: currentPassword,
				new_password: newPassword
            });
            if (response.status === 200) {
				// Reset form fields after success
				setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
				setPasswordSuccess(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setCurrentPasswordIncorrect(true); // Mark password field as conflicted
                setServerErrorPassword(t('error.password_incorrect', { ns: 'user_form' }));
            } else {
                setServerErrorPassword(t('password.server_error', { ns: 'account' }));
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
            }
        }
	}

	const handleSubmitDelete = async (event) => {
        event.preventDefault();
        setServerErrorDelete('');
        // Revalidate all fields on submit to capture any changes
        const newError = validatePassword(password);
        setErrorDelete(newError);

        if (newError) return;	// Prevent form submission if any error exists

        setShowDeleteModal(true); // Show confirmation modal
    };

	const handleConfirmDelete = async () => {
        try {
            const response = await api.delete(`/users/${user.user_id}`, {
                params: { password: password }
            });
            if (response.status === 200) {
                // Log out user
                dispatch({
                    type: 'LOGOUT'
                });
                navigate(delete_link);
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setPasswordIncorrect(true); // Mark password field as conflicted
                setServerErrorDelete(t('error.password_incorrect', { ns: 'user_form' }));
            } else {
                setServerErrorDelete(t('delete.server_error', { ns: 'account' }));
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
            }
        } finally {
            setShowDeleteModal(false); // Hide confirmation modal
        }
    };

    const handleCancelDelete = () => {
        setShowDeleteModal(false); // Hide confirmation modal
    };

	if (!userProfile) {
		return (<>
			<MetaTags />
			<h1>{ t('title', { ns: 'account' }) }</h1>
			<p>{ t('loading', { ns: 'common' }) }</p>
		</>);
	}
	
	return (<>
		<MetaTags />
		
		<h1>{ t('title', { ns: 'account' }) }</h1>
		
		<div>
			<h2>{ t('profile.title', { ns: 'account' }) }</h2>
			<form className="form-account" onSubmit={handleSubmitProfile} noValidate>
				<div className="form-group">
					<label htmlFor="email">{ t('form.email_label', { ns: 'user_form' }) }</label>
					<input
						type="email"
						id="email"
						value={email}
						disabled
					/>
				</div>

				<div className="form-group">
					<label htmlFor="firstName">{ t('form.first_name_label', { ns: 'user_form' }) }*</label>
					<input
						type="text"
						id="firstName"
						value={firstName}
						onChange={handleChangeProfile}
						onBlur={handleBlurProfile}
						onFocus={handleFocusProfile}
						required
						maxLength="50"
						className={errorsProfile.firstName ? 'error' : ''}
					/>
					{errorsProfile.firstName && <p className="error">{errorsProfile.firstName}</p>}
				</div>

				<div className="form-group">
					<label htmlFor="lastName">{ t('form.last_name_label', { ns: 'user_form' }) }</label>
					<input
						type="text"
						id="lastName"
						value={lastName}
						onChange={handleChangeProfile}
						onBlur={handleBlurProfile}
						onFocus={handleFocusProfile}
						maxLength="50"
						className={errorsProfile.lastName ? 'error' : ''}
					/>
					{errorsProfile.lastName && <p className="error">{errorsProfile.lastName}</p>}
				</div>

				<div id="submit-wrapper">
					<button type="submit">{ t('form.submit_button', { ns: 'user_form' }) }</button>
					{serverErrorProfile && <p className="error-message">{serverErrorProfile}</p>}
					{profileSuccess && <p className="success-message">{ t('profile.success', { ns: 'account' }) }</p>}
				</div>
			</form>
		</div>

		
		{partner && <div id="partner">
			<p>{t('profile.partner_message', { partner_name: partner, ns: 'account' })}</p>
		</div>}

		<div>
			<h2>{ t('password.title', { ns: 'account' }) }</h2>
			<form className="form-account" onSubmit={handleSubmitPassword} noValidate>
				<div className="form-group">
					<label htmlFor="currentPassword">{ t('form.password_label', { ns: 'user_form' }) }</label>
					<div className="password-wrapper">
						<input
							type={showCurrentPassword ? 'text' : 'password'}
							id="currentPassword"
							value={currentPassword}
							onChange={handleChangePassword}
							onBlur={handleBlurPassword}
							onFocus={handleFocusPassword}
							required
							minLength="10"
							maxLength="40"
							className={errorsPassword.currentPassword || currentPasswordIncorrect ? 'error' : ''}
						/>
						<button
							type="button"
							className="password-toggle"
							onClick={() => setShowCurrentPassword(prev => !prev)}
						>
							{showCurrentPassword ? <IoEyeOff /> : <IoEye />}
						</button>
					</div>
					{errorsPassword.currentPassword && <p className="error">{errorsPassword.currentPassword}</p>}
				</div>

				<div className="form-group">
					<label htmlFor="newPassword">{ t('password.new_password_label', { ns: 'account' }) }</label>
					<div className="password-wrapper">
						<input
							type={showNewPassword ? 'text' : 'password'}
							id="newPassword"
							value={newPassword}
							onChange={handleChangePassword}
							onBlur={handleBlurPassword}
							onFocus={handleFocusPassword}
							required
							minLength="10"
							maxLength="40"
							className={errorsPassword.newPassword ? 'error' : ''}
						/>
						<button
							type="button"
							className="password-toggle"
							onClick={() => setShowNewPassword(prev => !prev)}
						>
							{showNewPassword ? <IoEyeOff /> : <IoEye />}
						</button>
					</div>
					<ul className="password-criteria">
						<li className={passwordCriteria.length ? 'valid' : 'invalid'}>
							{passwordCriteria.length ? <IoCheckmark /> : <IoClose />}
							{ t('form.password_criteria_length', { ns: 'user_form' }) }
						</li>
						<li className={passwordCriteria.letter ? 'valid' : 'invalid'}>
							{passwordCriteria.letter ? <IoCheckmark /> : <IoClose />}
							{ t('form.password_criteria_letter', { ns: 'user_form' }) }
						</li>
						<li className={passwordCriteria.number ? 'valid' : 'invalid'}>
							{passwordCriteria.number ? <IoCheckmark /> : <IoClose />}
							{ t('form.password_criteria_number', { ns: 'user_form' }) }
						</li>
						<li className={passwordCriteria.specialChar ? 'valid' : 'invalid'}>
							{passwordCriteria.specialChar ? <IoCheckmark /> : <IoClose />}
							{ t('form.password_criteria_special_char', { ns: 'user_form' }) }
						</li>
					</ul>
					{errorsPassword.newPassword && <p className="error">{errorsPassword.newPassword}</p>}
				</div>

				<div className="form-group">
					<label htmlFor="confirmPassword">{ t('form.confirm_password_label', { ns: 'user_form' }) }</label>
					<div className="password-wrapper">
						<input
							type={showConfirmPassword ? 'text' : 'password'}
							id="confirmPassword"
							value={confirmPassword}
							onChange={handleChangePassword}
							onBlur={handleBlurPassword}
							onFocus={handleFocusPassword}
							required
							className={errorsPassword.confirmPassword ? 'error' : ''}
						/>
						<button
							type="button"
							className="password-toggle"
							onClick={() => setShowConfirmPassword(prev => !prev)}
						>
							{showConfirmPassword ? <IoEyeOff /> : <IoEye />}
						</button>
					</div>
					{errorsPassword.confirmPassword && <p className="error">{errorsPassword.confirmPassword}</p>}
				</div>

				<div id="submit-wrapper">
					<button type="submit">{ t('form.submit_button', { ns: 'user_form' }) }</button>
					{serverErrorPassword && <p className="error-message">{serverErrorPassword}</p>}
					{passwordSuccess && <p className="success-message">{ t('password.success', { ns: 'account' }) }</p>}
				</div>
			</form>
		</div>

		<div>
			<h2>{ t('delete.title', { ns: 'account' }) }</h2>
			<form className="form-account" onSubmit={handleSubmitDelete} noValidate>
			<div className="form-group">
				<label htmlFor="password">{ t('form.password_label', { ns: 'user_form' }) }</label>
				<div className="password-wrapper">
					<input
						type={showPassword ? 'text' : 'password'}
						id="password"
						value={password}
						onChange={handleChangeDelete}
						onBlur={handleBlurDelete}
						onFocus={handleFocusDelete}
						required
						minLength="10"
						maxLength="40"
						className={errorDelete || passwordIncorrect ? 'error' : ''}
					/>
					<button
						type="button"
						className="password-toggle"
						onClick={() => setShowPassword(prev => !prev)}
					>
						{showPassword ? <IoEyeOff /> : <IoEye />}
					</button>
				</div>
				{errorDelete && <p className="error">{errorDelete}</p>}
			</div>

			<div id="submit-wrapper">
					<button type="submit">{ t('delete.submit', { ns: 'account' }) }</button>
					{serverErrorDelete && <p className="error-message">{serverErrorDelete}</p>}
				</div>
			</form>
		</div>

		{/* Include the Delete Confirmation Modal */}
        {showDeleteModal && (
            <DeleteAccountModal
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
	</>);
}

AccountPage.namespaces = ['account', 'user_form'];

export default AccountPage;