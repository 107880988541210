import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Pagination, displayWidth, scrollToTop } from '@client/shared';
import '@client/shared/src/stylesheets/planche_contact.css';
import '@client/shared/src/stylesheets/pagination.css';

import ItemPanier from './ItemPanier';
import ItemSearch from './ItemSearch';

function PlancheContact({results = [], loading, no_results, panier_id = null, setRefresh, user_id, searchGalerieRef = null}) {
	// props.results = Array de code_image
	const { t } = useTranslation(['planche_contact']);
	const location = useLocation();

	// Ensure props.results is always an array
    const codesImages = results || [];

	// Pagination
	const [itemsPerPage, setItemsPerPage] = useState(20);
	const [currentPage, setCurrentPage] = useState(() => {
        const params = new URLSearchParams(location.search);
        const page = parseInt(params.get('page'), 10);
        return (page > 0) ? page : 1;
    });

	let totalPages, startIndex, endIndex, paginatedResults;
	if (codesImages && codesImages.length > 0) {
		totalPages = Math.ceil(codesImages.length / itemsPerPage);
		startIndex = (currentPage - 1) * itemsPerPage;
		endIndex = startIndex + itemsPerPage;
		paginatedResults = codesImages.slice(startIndex, endIndex);
	}

	const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

	// Update ItemsPerPage based on window width
	useEffect(() => {
        const updateItemsPerPage = () => {
            const width = window.innerWidth;
            if (width < 527) {
                setItemsPerPage(20); // 1 column
			} else if (width < 826) {
                setItemsPerPage(30); // 2 columns
            } else if (width < displayWidth.medium) {
                setItemsPerPage(40); // 3 columns
            } else if (width < displayWidth.wide) {
                setItemsPerPage(20); // medium
			} else {
                setItemsPerPage(40); // wide
            }
        };

        // Initial call to set the correct value
        updateItemsPerPage();

        // Add event listener to update when the window is resized
        window.addEventListener('resize', updateItemsPerPage);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateItemsPerPage);
        };
    }, []);

	// Sync currentPage with the 'page' query parameter
	// To reset currentPage on SearchForm submit
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const page = parseInt(params.get('page'), 10);
        if (page !== currentPage) {
            setCurrentPage((page > 0) ? page : 1);
        }
		// eslint-disable-next-line
    }, [location.search]);

	// Scroll to top when currentPage changes
	useEffect(() => {
		scrollToTop();
		// eslint-disable-next-line
	}, [currentPage]);

	// No codesImages
	if (!loading && codesImages.length === 0) {
		return (<div>
			<p className="text-message">{no_results}</p>
		</div>);
	}

	// Loading
	if (loading) {
		return (<div>
			<p className="text-message">{ t('loading', { ns: 'common' }) }</p>
		</div>);
	}

	// Galerie
	return (<>
		<div className="results-count">
            <p>{t('results_displayed', { count: paginatedResults.length, total: codesImages.length, ns: 'planche_contact' })}</p>
		</div>

		<Pagination
			currentPage={currentPage}
			totalPages={totalPages}
			onPageChange={handlePageChange}
		/>

		<div className="galerie planche-contact" ref={searchGalerieRef}>
			{panier_id ?
			paginatedResults.map((s) => (
				<ItemPanier key={s} code_image={s} panier_id={panier_id} setRefresh={setRefresh} user_id={user_id} />
			)) :
			paginatedResults.map((s) => (
				<ItemSearch key={s} code_image={s} />
			))}
		</div>

		<Pagination
			currentPage={currentPage}
			totalPages={totalPages}
			onPageChange={handlePageChange}
		/>
	</>);
}

export default PlancheContact;