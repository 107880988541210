import { forwardRef, useImperativeHandle, useEffect, useState } from 'react';

import { AutoCompleteDropdown } from './AutoCompleteDropdown';
import { validateIdFormat } from '../utils/champsFormat';

export const ChampId = forwardRef(({ language = null, id, label, value, onChange, options = [], maxLength = "50", setIsPeriodique = null, setDateParution = null }, ref) => {
	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	// options = Array de JSON avec properties "id" et "nom"
	// maxLength = taille max de value
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
	}

	const validate = () => {
		let validation = validateIdFormat(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	// Sort options based on the selected language
	const [sortedOptions, setSortedOptions] = useState(options);
    useEffect(() => {
        if (language) {
			console.log("sort", id)
            // Sort by 'nom_fr' if language is 'fr', otherwise sort by 'nom_en'
            const sorted = [...options].sort((a, b) => {
                if (language === 'fr') {
                    return a.nom_fr.localeCompare(b.nom_fr);
                } else {
                    return a.nom_en.localeCompare(b.nom_en);
                }
            });
            setSortedOptions(sorted);
        }
    }, [language]);

	const handleFocus = (e) => {
		setError(false);
	}

    return (<div className="form-group">
		<label htmlFor={id}>{label}</label>
        <AutoCompleteDropdown
			language={language}
			id={id}
			value={value}
			onChange={onChange}
			onFocus={handleFocus}
			options={sortedOptions}
			maxLength={maxLength}
			className={error ? "error" : ""}
			setIsPeriodique={setIsPeriodique}
			setDateParution={setDateParution}
		/>
    </div>);
});