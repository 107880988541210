import { useState, useRef, useEffect } from 'react';

export const SelectDropdown = ({
	language = null,
    value,
    options = [],
    onChange,
    placeholder,
    className,
    setSousCategorie = null
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleToggle = () => setIsOpen((prev) => !prev);

    const handleSelect = (selectedId) => {
        // Reset sousCategorie if categorie changes
        if (setSousCategorie) {
            setSousCategorie('');
        }

        onChange(selectedId);
        setIsOpen(false); // Close the dropdown after selection
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false); // Close dropdown if clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Filter out the selected value from the list of options
    const filteredOptions = options.filter((option) => option.id !== value);

    const getOptionLabel = (option) => {
        if (language === 'fr') {
            return option.nom_fr || option.nom || 'Not found';
        } else if (language === 'en') {
            return option.nom_en || option.nom || 'Not found';
        } else {
            return option.nom || 'Not found';
        }
    };

    const selectedOption = options.find((option) => option.id === value);

    return (
        <div className="custom-dropdown custom-select" ref={dropdownRef} tabIndex={0}>
            <div
                className={`custom-dropdown-header ${className}`}
                onClick={handleToggle} // Toggle dropdown on click
            >
                {selectedOption ? getOptionLabel(selectedOption) : placeholder}

                <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>&#9660;</span>
            </div>
            {isOpen && (
                <ul className="custom-dropdown-list">
                    {/* Option to clear the selection and reset to placeholder */}
                    <li
                        key="clear"
                        onClick={() => handleSelect('')} // Clear selection
                        className="custom-dropdown-item"
                    >
                        {placeholder}
                    </li>
                    {filteredOptions.map((option) => (
                        <li
                            key={option.id}
                            onClick={() => handleSelect(option.id)}
                            className="custom-dropdown-item"
                        >
                            {getOptionLabel(option)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};