import { useLanguage } from '../contexts/LanguageContext';
import { Helmet } from 'react-helmet-async';

const metaContent = {
	en: {
		not_found: {
			title: "Images GuenoMiller"
		},
		home: {
			title: "Images GuenoMiller",
			description: "17,000 vintage images of fashion, architecture, interior decor, and gastronomy from the 17th century to the 1950s",
		},
		about: {
			title: "About | Images GuenoMiller",
			description: "Learn more about Guénolée Milleret the founder of the Patrimonial image library GuenoMiller",
		},
		account: {
			title: "Account | Images GuenoMiller"
		},
		books: {
			title: "Books | Images GuenoMiller",
			description: "View and purchase books published by Guénolée Milleret",
		},
		compositions: {
			title: "Compositions | Images GuenoMiller",
			description: "Image compositions from the Patrimonial image library GuenoMiller",
		},
		contact: {
			title: "Contact | Images GuenoMiller",
			description: "Contact Guénolée Milleret the founder of the Patrimonial image library GuenoMiller",
		},
		favourites: {
			title: "Favourites | Images GuenoMiller"
		},
		forbidden: {
			title: "Images GuenoMiller"
		},
		forgotten_password: {
			title: "Forgotten password | Images GuenoMiller"
		},
		legal_notices: {
			title: "Legal notices | Images GuenoMiller"
		},
		login: {
			title: "Log in | Images GuenoMiller",
			description: "Log in to the Patrimonial image library GuenoMiller",
		},
		media: {
			title: "Image page | Images GuenoMiller"
		},
		register: {
			title: "Register | Images GuenoMiller",
			description: "Register to browse the whole Patrimonial image library GuenoMiller",
		},
		reset_password: {
			title: "Reset password | Images GuenoMiller"
		},
		search: {
			title: "Search | Images GuenoMiller"
		},
		source: {
			title: "Source page | Images GuenoMiller"
		},
		terms_of_service: {
			title: "TOS | Images GuenoMiller"
		}
	},
	fr: {
		not_found: {
			title: "Images GuenoMiller"
		},
		home: {
			title: "Images GuenoMiller",
			description: "17 000 images patrimoniales de mode, architecture, décor intérieur et gastronomie du XVIIe siècle aux années 1950",
		},
		about: {
			title: "À propos | Images GuenoMiller",
			description: "Plus d'informations à propos de Guénolée Milleret la fondatrice de l'iconothèque patrimoniale GuenoMiller",
		},
		account: {
			title: "Compte | Images GuenoMiller"
		},
		books: {
			title: "Livres | Images GuenoMiller",
			description: "Découvrir et commander les livres publiés par Guénolée Milleret",
		},
		compositions: {
			title: "Compositions | Images GuenoMiller",
			description: "Compositions d'images provenant de l'iconothèque patrimoniale GuenoMiller",
		},
		contact: {
			title: "Contact | Images GuenoMiller",
			description: "Contacter Guénolée Milleret la fondatrice de l'iconothèque patrimoniale GuenoMiller",
		},
		favourites: {
			title: "Favoris | Images GuenoMiller"
		},
		forbidden: {
			title: "Images GuenoMiller"
		},
		forgotten_password: {
			title: "Mot de passe oublié | Images GuenoMiller"
		},
		legal_notices: {
			title: "Mentions légales | Images GuenoMiller"
		},
		login: {
			title: "Connexion | Images GuenoMiller",
			description: "Se connecter à l'iconothèque patrimoniale GuenoMiller",
		},
		media: {
			title: "Fiche image | Images GuenoMiller"
		},
		register: {
			title: "Inscription | Images GuenoMiller",
			description: "S'inscrire pour consulter la totalité de l'iconothèque patrimoniale GuenoMiller",
		},
		reset_password: {
			title: "Réinitialiser mot de passe | Images GuenoMiller"
		},
		search: {
			title: "Recherche | Images GuenoMiller"
		},
		source: {
			title: "Fiche source | Images GuenoMiller"
		},
		terms_of_service: {
			title: "CGU | Images GuenoMiller"
		}
	}
};

const MetaTags = () => {
	const { language } = useLanguage();
	const languagePrefix = language === 'fr' ? '/fr' : '';

	// Get the pathname from the current URL, excluding query parameters
	const currentPath = window.location.pathname.split('?')[0];

	// Split the path into segments
	const pathSegments = currentPath.split('/').filter(Boolean);

	// Define known static paths for your application (update this list as necessary)
	const basePaths = [
		'about',
		'account',
		'books',
		'compositions',
		'contact',
		'favourites',
		'forbidden',
		'forgotten_password',
		'legal_notices',
		'login',
		'media',
		'register',
		'reset_password',
		'search',
		'source',
		'terms_of_service'
		// Add more paths as necessary
	];

	// Find the first segment that matches known static paths
	const currentPage = pathSegments.find(segment => basePaths.includes(segment)) || 'home';

	// Generate the canonical link path
	const linkPath = currentPage === 'home' ? '/' : `/${currentPage}`;
	const canonicalPath = `${languagePrefix}${linkPath}`;

	// Fetch meta content based on the current page and language
	const pageMeta = metaContent[language][currentPage] || metaContent[language]['home'];

	return (
		<Helmet>
			<title>{pageMeta.title}</title>
			<meta name="description" content={pageMeta.description} />

			{/* Canonical link */}
			<link rel="canonical" href={`https://www.imagesguenomiller.com${canonicalPath}`} />
			
			{/* Alternate language links */}
			<link rel="alternate" href={`https://www.imagesguenomiller.com${linkPath}`} hrefLang="x-default" />
			<link rel="alternate" href={`https://www.imagesguenomiller.com${linkPath}`} hrefLang="en" />
			<link rel="alternate" href={`https://www.imagesguenomiller.com/fr${linkPath}`} hrefLang="fr" />
		</Helmet>
	);
};

export default MetaTags;
