import axios from 'axios';

export const api = axios.create({
	baseURL: '/api',  // Use a relative URL
	withCredentials: true
});

api.defaults.withCredentials = true;

export const setupInterceptors = (dispatch, setShowRateLimitModal, setRetryAfter) => {
    return new Promise((resolve) => {
        api.interceptors.response.use(
            function (response) {
                // Any status code that lies within the range of 2xx causes this function to trigger
                // Do something with response data
                return response;
            },
            function (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        // Update the auth context = dispatch logout action
                        dispatch({
                            type: 'LOGOUT'
                        });
                    }

                    if (error.response.status === 403 && error.response.data.accessDenied) {
                        // Retrieve user language from localStorage
                        const userLanguage = localStorage.getItem('userLanguage') || 'en';
                        
                        // Determine the path prefix based on the language
                        const languagePrefix = userLanguage === 'fr' ? '/fr' : '';
                        
                        // Navigate to the forbidden page
                        window.location.assign(`${languagePrefix}/forbidden`);
                    }

                    // Display a modal when the user reaches the rate limit
                    if (error.response.status === 499) {
                        const retryAfter = error.response.data.retry_after;
                        setRetryAfter(retryAfter); // Set retry after value in seconds
                        setShowRateLimitModal(true); // Show the modal
                    }
                }
                return Promise.reject(error);
            }
        );
		resolve(); // Resolve the promise when the interceptor is set up
    });
};