import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuthContext, displayWidth } from '@client/shared';

import MetaTags from './MetaTags';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import useLocalizedPath from '../hooks/useLocalizedPath';

import '../stylesheets/search.css';

function SearchPage() {
	const { t } = useTranslation(['search']);
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuthContext();
	const redirect_link = useLocalizedPath("/login");

	// Protect page from visitors
    useLayoutEffect(() => {
        if (!user) {
			navigate(redirect_link);
		}
		// eslint-disable-next-line
    }, [user, location.pathname, navigate]);

	const [results, setResults] = useState([]);
	const [loading, setLoading] = useState(false);

	const searchFormRef = useRef(null);
  	const searchGalerieRef = useRef(null);

	// Set the height of .search-galerie based on the height of .search-form
	useEffect(() => {
		const searchForm = searchFormRef.current;
		const searchGalerie = searchGalerieRef.current;

		const updateGalerieHeight = () => {
			// Check if window width is greater than or equal to displayWidth.medium
			const shouldApplyEffect = window.innerWidth >= displayWidth.medium;

			if (searchForm && searchGalerie) {
				// si display medium ou plus, fix height
				if (shouldApplyEffect) {
					const formHeight = searchForm.offsetHeight;
					// console.log("form height:", formHeight);
					// console.log("galerie height:", searchGalerie.offsetHeight);
					searchGalerie.style.height = `${formHeight + 102}px`;
					// console.log("new galerie height:", searchGalerie.style.height);
				}
				// sinon remove fix height
				else {
					searchGalerie.style.height = "auto";
				}
			}
		};
	
		// Observe changes in the search form's size
		const observer = new ResizeObserver(updateGalerieHeight);
		if (searchForm) {
			observer.observe(searchForm);
		}
	
		// Trigger the height update when the results state changes
		updateGalerieHeight();
	
		// Monitor window resize
		window.addEventListener('resize', updateGalerieHeight);
	
		// Cleanup listener and observer on component unmount
		return () => {
			if (searchForm) {
				observer.unobserve(searchForm);
		  	}
		  	window.removeEventListener('resize', updateGalerieHeight);
		};
	  }, [results]);

	// Make Suspense display the Loader if user isn't loaded yet
    if (!user) {
        return null;
    }

	return (<>
		<MetaTags />
		
		<h1>{ t('title', { ns: 'search' }) }</h1>
		
		<div id="search-container">
			<SearchForm setResults={setResults} setLoading={setLoading} searchFormRef={searchFormRef} />
			<SearchResults results={results} loading={loading} searchGalerieRef={searchGalerieRef} />
		</div>
	</>);
}

SearchPage.namespaces = ['search', 'planche_contact'];

export default SearchPage;