export const ConfirmModal = ({ message, confirm, cancel, onConfirm, onCancel }) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <p>{message}</p>
				<div className="modal-buttons">
					<button onClick={onConfirm}>{confirm}</button>
					<button id="cancel" onClick={onCancel}>{cancel}</button>
				</div>
            </div>
        </div>
    );
};