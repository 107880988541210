import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const Pagination = ({ currentPage, totalPages, onPageChange}) => {
	const location = useLocation();
    const navigate = useNavigate();
	const [maxPagesToShow, setMaxPagesToShow] = useState(5); // Default value

	useEffect(() => {
        // Function to update maxPagesToShow based on window width
		// I tuned it manually using DevTools
        const updateMaxPagesToShow = () => {
            const width = window.innerWidth;
            if (width < 527) {
                setMaxPagesToShow(3);
            } else if (width < 826) {
                setMaxPagesToShow(6);
            } else if (width < 1089) {
                setMaxPagesToShow(10);
			} else if (width < 1140) {
				setMaxPagesToShow(14);
			} else if (width < 1433) {
				setMaxPagesToShow(6);
			} else if (width < 1673) {
				setMaxPagesToShow(10);
			} else {
				setMaxPagesToShow(13);
			}
        };

        // Initial call to set the correct value
        updateMaxPagesToShow();

        // Add event listener to update when the window is resized
        window.addEventListener('resize', updateMaxPagesToShow);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateMaxPagesToShow);
        };
    }, []);

	// Update the URL with the current page whenever it changes
	useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('page') !== String(currentPage)) {
            params.set('page', currentPage);
            navigate(`${location.pathname}?${params.toString()}`, { replace: true });
        }
    }, [currentPage, location.search, location.pathname, navigate]);

    const ellipsis = '•••';

    const createPageNumbers = () => {
		// Set currentPage to totalPages if currentPage > totalPages
		if (currentPage > totalPages) {
			onPageChange(totalPages);
		}

		const pages = [];
	
		if (totalPages <= maxPagesToShow) {
			// If total pages are less than or equal to max pages to show, display all pages
			for (let i = 1; i <= totalPages; i++) {
				pages.push(i);
			}
		} else {
			if (maxPagesToShow === 3) {
				// Special case when maxPagesToShow is 3
				if (currentPage >= totalPages - 2) {
					// If near the end: show the last three pages
					pages.push(<span key="ellipsis" className="ellipsis">{ellipsis}</span>);
					pages.push(totalPages - 2, totalPages - 1, totalPages);
				} else {
					// Otherwise: show current page, next page, ellipsis, last page
					pages.push(currentPage, currentPage + 1);
					pages.push(<span key="ellipsis" className="ellipsis">{ellipsis}</span>);
					pages.push(totalPages);
				}
			} else {
				// General case for other maxPagesToShow values
				const rangeStart = Math.max(2, currentPage - Math.floor((maxPagesToShow - 3) / 2));
				const rangeEnd = Math.min(totalPages - 1, currentPage + Math.floor((maxPagesToShow - 3) / 2));
		
				if (currentPage <= Math.floor((maxPagesToShow - 3) / 2) + 2) {
					// Near the Start: Show the current page, the next pages, an ellipsis, and the last page.
					for (let i = 1; i <= maxPagesToShow - 2; i++) {
						pages.push(i);
					}
					pages.push(<span key="ellipsis1" className="ellipsis">{ellipsis}</span>);
					pages.push(totalPages);
				} else if (currentPage >= totalPages - Math.floor((maxPagesToShow - 3) / 2) - 1) {
					// Near the End: Show the first page, an ellipsis, and the last pages including the current page.
					pages.push(1);
					pages.push(<span key="ellipsis2" className="ellipsis">{ellipsis}</span>);
					for (let i = totalPages - (maxPagesToShow - 3); i <= totalPages; i++) {
						pages.push(i);
					}
				} else {
					// In the Middle: Show the first page, an ellipsis, pages around the current page, another ellipsis, and the last page.
					pages.push(1);
					pages.push(<span key="ellipsis3" className="ellipsis">{ellipsis}</span>);
					for (let i = rangeStart; i <= rangeEnd; i++) {
						pages.push(i);
					}
					pages.push(<span key="ellipsis4" className="ellipsis">{ellipsis}</span>);
					pages.push(totalPages);
				}
			}
		}
	
		return pages;
	};	

    return (
        <div className="pagination">
            <button
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
            >
                &#xab; {/* Double left-pointing angle quotation mark */}
            </button>
            <button
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
            >
                &#x2039; {/* Single left-pointing angle quotation mark */}
            </button>
            {createPageNumbers().map((page, index) => (
                <button
                    key={index}
                    onClick={() => typeof page === 'number' && onPageChange(page)}
                    className={`page-button ${page === currentPage ? 'active' : ''}`}
                    disabled={typeof page !== 'number'}
                >
                    {page}
                </button>
            ))}
            <button
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
            >
                &#x203a; {/* Single right-pointing angle quotation mark */}
            </button>
            <button
                onClick={() => onPageChange(totalPages)}
                disabled={currentPage === totalPages}
            >
                &#xbb; {/* Double right-pointing angle quotation mark */}
            </button>
        </div>
    );
};