import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuthContext, api } from '@client/shared';
import '@client/shared/src/stylesheets/form.css';

import MetaTags from './MetaTags';
import LocalizedLink from './LocalizedLink';
import { useLanguage } from '../contexts/LanguageContext';
import useLocalizedPath from '../hooks/useLocalizedPath';

function ForgottenPasswordPage() {
	const { t } = useTranslation(['forgotten_password', 'user_form', 'resend_verification']);
	const location = useLocation();
	const navigate = useNavigate();
	const { language } = useLanguage();
	const { user } = useAuthContext();
	const redirect_link = useLocalizedPath("/");
	
	// redirect connected users
    useLayoutEffect(() => {
        if (user) {
			navigate(redirect_link);
		}
		// eslint-disable-next-line
    }, [user, location.pathname, navigate]);

	const [email, setEmail] = useState('');
	const [errors, setErrors] = useState({});
	const [serverError, setServerError] = useState('');
	const [emailNotFound, setEmailNotFound] = useState(false);
	const [emailSuccess, setEmailSuccess] = useState(<></>);
	const [actionButton, setActionButton] = useState(<></>);

	const validateEmail = (value) => {
        if (!value) {
            return t('error.email_empty', { ns: 'user_form' });
        } else if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            return t('error.email_format', { ns: 'user_form' });
        }
        return '';
    };

	const handleChange = (e) => {
        const { id, value } = e.target;

		if (id === 'email') {
			setEmail(value.trim());
            setEmailNotFound(false);	// Reset conflict state on change
		}
	}

	const handleBlur = (e) => {
		const { id, value } = e.target;
		let error = '';

		if (id === 'email') {
			error = validateEmail(value);
			setErrors(prev => ({ ...prev, [id]: error }));
		}
	};

	const handleFocus = (e) => {
		// Reset fields error on focus
		const { id } = e.target;
		setErrors(prev => ({ ...prev, [id]: '' }));
	};

	const handleResendButton = async () => {
		try {
            const response = await api.post('/public/resend_verification', {
                email,
				language
            });
			setActionButton(<></>);
			if (response.status === 200) {
				setServerError('');
				setEmailSuccess(t('success', { ns: 'resend_verification' }));
            }
        } catch (error) {
			setActionButton(<></>);
			if (error.response) {
				if (error.response.status === 400) {
					setServerError( t('error.user_not_found', { ns: 'user_form' }) );
				}
				if (error.response.status === 403) {
					// Adjust reset_time to the user's local timezone
					const reset_time = new Date(error.response.data.reset_time);
					const display_reset_time = reset_time.toLocaleString(); // Converts to local time and formats it
					const message = t('too_many', { ns: 'resend_verification' }) + display_reset_time + t('contact', { ns: 'resend_verification' });
					setServerError(message);
				}
			} else {
                setServerError( t('fail', { ns: 'resend_verification' }) );
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
            }
        }
	};

	const handleSubmit = async (event) => {
        event.preventDefault();
		setActionButton(<></>);
		setEmailSuccess(<></>);
        // Revalidate all fields on submit to capture any changes
        const newErrors = {
            email: validateEmail(email)
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error)) return; // Prevent form submission if any error exists

        try {
            const response = await api.post('/public/request_password_reset', {
                email,
                language
            });
            if (response.status === 200) {
				setServerError('');
				setEmailSuccess(t('success', { ns: 'forgotten_password' }));
				setActionButton(<div id="inscription"><LocalizedLink to="/login">{ t('header.link_login', { ns: 'common' }) }</LocalizedLink></div>);
            }
        } catch (error) {
			if (error.response) {
				if (error.response.status === 404) {
					setEmailNotFound(true); // Mark email field as conflicted
                	setServerError( t('error.user_not_found', { ns: 'user_form' }) );
					setActionButton(<div id="inscription"><LocalizedLink to="/register">{ t('link_register', { ns: 'common' }) }</LocalizedLink></div>);
				}
				if (error.response.status === 401) {
					setServerError( t('email_not_verified', { ns: 'resend_verification' }) );
					setActionButton(<button type="button" onClick={handleResendButton}>{ t('resend_button', { ns: 'resend_verification' }) }</button>);
				}
				if (error.response.status === 403) {
					setServerError( t('error.already_sent', { ns: 'forgotten_password' }) );
					setActionButton(<div id="inscription"><LocalizedLink to="/contact">{ t('header.link_contact', { ns: 'common' }) }</LocalizedLink></div>);
				}
			} else {
                setServerError( t('error.forgotten_password_error', { ns: 'forgotten_password' }) );
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
            }
        }
    };
	
	return (<>
		<MetaTags />
		
		<h1>{ t('title', { ns: 'forgotten_password' }) }</h1>
		<form className="form-account" onSubmit={handleSubmit} noValidate>
			<div className="form-group">
				<label htmlFor="email">{ t('form.email_label', { ns: 'user_form' }) }</label>
				<input
					type="email"
					id="email"
					value={email}
					onChange={handleChange}
					onBlur={handleBlur}
					onFocus={handleFocus}
					required
					maxLength="254"
					className={errors.email || emailNotFound ? 'error' : ''}
				/>
				{/* Only show error message if there is a specific validation error */}
				{errors.email && <p className="error">{errors.email}</p>}
			</div>

			<div id="submit-wrapper">
				<button type="submit">{ t('form.submit_button', { ns: 'user_form' }) }</button>
				{serverError && <p className="error-message">{serverError}</p>}
				{emailSuccess && <p className="success-message">{emailSuccess}</p>}
				{actionButton}
			</div>
		</form>
	</>);
}

ForgottenPasswordPage.namespaces = ['forgotten_password', 'user_form', 'resend_verification'];

export default ForgottenPasswordPage;