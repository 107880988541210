import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleOutline } from 'react-icons/io5';

import { api } from '@client/shared';
import '@client/shared/src/stylesheets/planche_contact.css';

import DeleteImagePanierModal from './DeleteImagePanierModal';
import LocalizedLink from './LocalizedLink';

function ItemPanier(props) {
	const { t } = useTranslation([]);

	const [thumbnailUrl, setThumbnailUrl] = useState(null);
	const { code_image, panier_id, setRefresh, user_id } = props;

	useEffect(() => {
		// Function to fetch the thumbnail from the server
		const fetchThumbnail = async () => {
			try {
				// Make the request to get the thumbnail image as a Blob
				const response = await api.get(`/medias/thumbnail/${code_image}`, {
					responseType: 'blob', // This tells axios to treat the response as a Blob
				});

				// Handle the response
				if (response.status === 200 || response.status === 304) {
					const url = URL.createObjectURL(response.data);
					setThumbnailUrl(url);
				}
			} catch (error) {
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				} else {
					console.error(`Error fetching thumbnail ${code_image}: `, error);
				}
			}
		};

		// Call the function to fetch the thumbnail
		fetchThumbnail();
	}, [code_image]);
	
	const mediaLink = `/media/${code_image}`;
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const handleDelete = () => {
		setShowDeleteModal(true);
	}

	const handleConfirmDelete = async () => {
        try {
            const response = await api.delete(`/users/${user_id}/panier/${code_image}`, {
                params: { panier_id: panier_id }
            });
            if (response.status === 200) {
                setRefresh((prev) => !prev);
            }
        } catch (error) {
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
        } finally {
            setShowDeleteModal(false); // Hide confirmation modal
        }
    };

	const handleCancelDelete = () => {
        setShowDeleteModal(false); // Hide confirmation modal
    };

	return (<>
		<div className="item-planche-contact">
			<LocalizedLink to={mediaLink} rel="nofollow">
				<div className="item-thumbnail">
					{thumbnailUrl ? (
						<img src={thumbnailUrl} alt="Thumbnail" />
					) : (
						<p>{ t('loading', { ns: 'common' }) }</p>
					)}
				</div>
				<p className="planche-contact-code-image">{code_image}</p>
			</LocalizedLink>
			<div className="planche-contact-button" onClick={handleDelete}><IoCloseCircleOutline /></div>
		</div>

		{showDeleteModal && (
            <DeleteImagePanierModal
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
	</>);
}

export default ItemPanier;