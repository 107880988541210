import { forwardRef, useImperativeHandle, useState } from 'react';

import { validateIdFormat } from '../utils/champsFormat';
import { SelectDropdown } from './SelectDropdown';

export const ChampCategorie = forwardRef(({ language = null, id, label = "Catégorie", value, onChange, options = [], placeholder = "Sélectionner…", setSousCategorie }, ref) => {
	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
	}

	const validate = () => {
		let validation = validateIdFormat(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	const handleFocus = (e) => {
		setError(false);
	}

    return (<div className="form-group">
		<label htmlFor={id}>{label}</label>
        <SelectDropdown
			language={language}
			id={id}
			options={options}
			value={value}
			onChange={onChange}
			onFocus={handleFocus}
			placeholder={placeholder}
			className={error ? "error" : ""}
			setSousCategorie={setSousCategorie}
		/>
    </div>);
});