import { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseCircleOutline } from 'react-icons/io5';

import { validateAnnee } from '@client/shared';

const AutoCompleteDropdownAnnee = ({ id, value, onChange, onFocus, options = [], className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [displayValue, setDisplayValue] = useState('');

    useEffect(() => {
        if (options.includes(value)) {
            setDisplayValue(value);
        } else {
            setDisplayValue('');
        }
    }, [value, options]);

    const handleSelect = (option) => {
        setDisplayValue(option);
        onChange(option);
        setIsOpen(false);
	};

    const handleInputChange = (event) => {
        const userInput = event.target.value;
        setDisplayValue(userInput);
        const filtered = options.filter(option =>
            option.includes(userInput)
        );
        setFilteredOptions(filtered);
        setIsOpen(true);
    };

    const handleBlur = () => {
        if (!options.includes(displayValue)) {
            setDisplayValue('');
            onChange('');
        }
        setIsOpen(false);
    };

    const handleFocus = (e) => {
        if (options.length === 0) {
            onFocus(e);
        }
    };

    return (
        <div className="custom-dropdown">
            <input
                id={id}
                value={displayValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                maxLength="9"
				className={className}
            />
            {isOpen && filteredOptions.length > 0 && (
                <ul className="custom-dropdown-list">
                    {filteredOptions.map((option, index) => (
                        <li
                            key={index}
                            onMouseDown={() => handleSelect(option)}
                            className="custom-dropdown-item"
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const SourceAnnee = forwardRef(({ id, value, onChange, options = [] }, ref) => {
	const { t } = useTranslation([]);

	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	// options = Array de String
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
	}

	const validate = () => {
		let validation = validateAnnee(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	const handleFocus = (e) => {
		setError(false);
	}

	const remove = () => {
        onChange('');
    };

    return (<div className="form-group source-champ">
		<label htmlFor={id} className="source-champ-nom">{t('champs.annee', { ns: 'common' })}</label>
        <AutoCompleteDropdownAnnee
			id={id}
			value={value}
			onChange={onChange}
			onFocus={handleFocus}
			options={options}
			className={`source-champ-input ${error ? "error" : ""}`}
		/>
		<button type="button" onClick={remove} className="remove-button"><IoCloseCircleOutline /></button>
    </div>);
});

export default SourceAnnee;