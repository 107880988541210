import { forwardRef, useImperativeHandle, useEffect, useState } from 'react';

import { validateIdFormat } from '../utils/champsFormat';
import { SelectDropdown } from './SelectDropdown';

export const ChampSousCategorie = forwardRef(({ language = null, id, label = "Sous-catégorie", value, onChange, options = [], placeholder = "Sélectionner…", categorie, setCategorie }, ref) => {
	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
	}

	const validate = () => {
		let validation = validateIdFormat(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	/* Link categorie and sousCategorie fields */
	// Auto update categorie based on sousCategorie
	useEffect(() => {
        if (value) {
			// Find the sousCategorie where option.id === value
            const selectedOption = options.find(option => option.id === value);
            if (selectedOption) {
                // Update the categorie based on the parent of the selected sousCategorie
                setCategorie(selectedOption.parent);
            }
        }
    }, [value, options, setCategorie]);

	// Filter sousCategories options based on categorie
	const [filteredOptions, setFilteredOptions] = useState(options);
	useEffect(() => {
        if (categorie) {
            // Filter the sousCategories to only include those where option.parent === categorie
            const filtered = options.filter(option => option.parent === categorie);
            setFilteredOptions(filtered);
        } else {
            // If no categorie is selected, reset to all options
            setFilteredOptions(options);
        }
    }, [categorie, options]);

	// Sort options based on the selected language
    useEffect(() => {
        if (language) {
            // Sort by 'nom_fr' if language is 'fr', otherwise sort by 'nom_en'
            const sorted = [...filteredOptions].sort((a, b) => {
                if (language === 'fr') {
                    return a.nom_fr.localeCompare(b.nom_fr);
                } else {
                    return a.nom_en.localeCompare(b.nom_en);
                }
            });
            setFilteredOptions(sorted);
        }
    }, [language]);

	const handleFocus = (e) => {
		setError(false);
	}

    return (<div className="form-group">
		<label htmlFor={id}>{label}</label>
        <SelectDropdown
			language={language}
			id={id}
			options={filteredOptions}
			value={value}
			onChange={onChange}
			onFocus={handleFocus}
			placeholder={placeholder}
			className={error ? "error" : ""}
		/>
    </div>);
});