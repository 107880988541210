import { useState, useEffect } from 'react';

const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const AutoCompleteDropdown = ({
	language = null, // If language is null, use default mechanism; otherwise, use language-specific logic
    id,
    value,
    onChange,
    onFocus,
    options = [],
    maxLength = 50,
    className = "",
    setIsPeriodique = null,
    setDateParution = null
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [displayValue, setDisplayValue] = useState('');

    // Normalize options based on whether language is specified or not
    const normalizedOptions = options.map(option => ({
        ...option,
        normalizedNom: removeAccents(
            language
                ? (language === 'fr' ? option.nom_fr : option.nom_en).toLowerCase()
                : option.nom.toLowerCase()
        )
    }));

    // Update the displayValue based on the current value and options
    useEffect(() => {
        const selectedOption = options.find(option => option.id === value);
        if (selectedOption) {
            const label = language
                ? (language === 'fr' ? selectedOption.nom_fr : selectedOption.nom_en)
                : selectedOption.nom;
            setDisplayValue(label);
        } else {
            setDisplayValue('');
        }
    }, [value, options, language]);

    // Handle selection of an option
    const handleSelect = (option) => {
        const label = language
            ? (language === 'fr' ? option.nom_fr : option.nom_en)
            : option.nom;
        setDisplayValue(label);
        onChange(option.id);
        setIsOpen(false);

        // For "source" field
        if (setIsPeriodique && option.type_publication === "Périodique") {
            setIsPeriodique(true);
        }
    };

    // Handle user input changes
    const handleInputChange = (event) => {
        const userInput = removeAccents(event.target.value.toLowerCase());
        setDisplayValue(event.target.value);

        // For "source" field
        if (setIsPeriodique) {
            setDateParution('');
            setIsPeriodique(false);
        }

        const filtered = normalizedOptions.filter(option =>
            option.normalizedNom.includes(userInput)
        );
        setFilteredOptions(filtered);
    };

    // Handle when input loses focus
    const handleBlur = () => {
        const selectedOption = options.find(option => {
            const label = language
                ? (language === 'fr' ? option.nom_fr : option.nom_en)
                : option.nom;
            return label === displayValue;
        });

        if (!selectedOption) {
            setDisplayValue(''); // Reset the input display value
            onChange(''); // Reset the value passed to the parent component
        }

        setIsOpen(false); // Close the dropdown
    };

    // Handle when input gains focus
    const handleFocus = (e) => {
        onFocus(e);

        // Show all options when the input is focused if the user hasn't typed anything
        const filtered = displayValue
            ? normalizedOptions.filter(option =>
                option.normalizedNom.includes(removeAccents(displayValue.toLowerCase()))
            )
            : normalizedOptions;

        setFilteredOptions(filtered);
        setIsOpen(true);
    };

    return (
        <div className="custom-dropdown">
            <input
                id={id}
                value={displayValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                maxLength={maxLength}
                className={className}
            />
            {isOpen && filteredOptions.length > 0 && (
                <ul className="custom-dropdown-list">
                    {filteredOptions.map((option) => (
                        <li
                            key={option.id}
                            onMouseDown={() => handleSelect(option)}
                            className="custom-dropdown-item"
                        >
                            {language
                                ? (language === 'fr' ? option.nom_fr : option.nom_en)
                                : option.nom}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};