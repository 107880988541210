import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { startTransition } from 'react';

export const TransitionRoute = ({ element }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        startTransition(() => {
            navigate({
                pathname: location.pathname,
                search: location.search, // Preserve query parameters
                hash: location.hash      // Preserve hash
            }, { replace: true });
        });
    }, [location.pathname, location.search, location.hash, navigate]);

    return element;
}