import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoCheckmark, IoClose, IoEye, IoEyeOff } from 'react-icons/io5';

import { useAuthContext, api } from '@client/shared';
import '@client/shared/src/stylesheets/form.css';

import MetaTags from './MetaTags';
import { useLanguage } from '../contexts/LanguageContext';
import useLocalizedPath from '../hooks/useLocalizedPath';

import '../stylesheets/register.css';

function RegisterPage() {
    const { t } = useTranslation(['register', 'user_form']);
	const location = useLocation();
	const navigate = useNavigate();
	const { language } = useLanguage();
	const { user } = useAuthContext();
	const redirect_link = useLocalizedPath("/");

	// redirect connected users
    useLayoutEffect(() => {
        if (user) {
			navigate(redirect_link);
		}
		// eslint-disable-next-line
    }, [user, location.pathname, navigate]);

	const [loginPath, setLoginPath] = useState(language === 'fr' ? '/fr/login' : '/login');
	const [termsLink, setTermsLink] = useState(`https://imagesguenomiller.com/CGU_${language}.pdf`);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
	const [termsAccepted, setTermsAccepted] = useState(false);
    const [errors, setErrors] = useState({});
    const [passwordCriteria, setPasswordCriteria] = useState({
        length: false,
        letter: false,
        number: false,
        specialChar: false,
        match: false
    });
    const [serverError, setServerError] = useState('');
    const [emailConflict, setEmailConflict] = useState(false);

	// Show/Hide password state
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	// Update content based on language
	useEffect(() => {
		setLoginPath(language === 'fr' ? '/fr/login' : '/login');
		setTermsLink(`https://imagesguenomiller.com/CGU_${language}.pdf`);
	}, [language]);

    const validateEmail = (value) => {
        if (!value) {
            return t('error.email_empty', { ns: 'user_form' });
        } else if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            return t('error.email_format', { ns: 'user_form' });
        }
        return '';
    };

    const validateFirstName = (value) => {
        if (!value) {
            return t('error.first_name_empty', { ns: 'user_form' });
        } else if (!value.match(/^[a-zA-ZÀ-ÿ'-]+$/)) {
            return t('error.first_name_format', { ns: 'user_form' });
        }
        return '';
    };

    const validateLastName = (value) => {
        if (value && !value.match(/^[a-zA-ZÀ-ÿ'-]*$/)) {
            return t('error.last_name_format', { ns: 'user_form' });
        }
        return '';
    };

    const checkPasswordCriteria = (value) => {
		const criteria = {
			length: value.length >= 10 && value.length <= 40,
			letter: /[a-zA-Z]/.test(value),
			number: /[0-9]/.test(value),
			specialChar: /[!@#$%^&*()_+{}[\]:;"'<>,.?~`|\\]/.test(value),
		};
		setPasswordCriteria(criteria);
		return criteria;
	};
	
	const validatePassword = (value) => {
		const criteria = checkPasswordCriteria(value);
		if (Object.values(criteria).includes(false)) {
			return t('error.password_format', { ns: 'user_form' });
		}
		return '';
	};

    const validateConfirmPassword = (value) => {
        if (value !== password) {
            return t('error.password_mismatch', { ns: 'user_form' });
        }
        return '';
    };

	const validateTermsCheckbox = () => {
		if (!termsAccepted) {
			return t('error.terms_not_accepted', { ns: 'register' });
		}
		return '';
	};

    const handleChange = (e) => {
        const { id, value } = e.target;

        switch (id) {
            case 'email':
                setEmail(value.trim());
                setEmailConflict(false);	// Reset conflict state on change
                break;
            case 'firstName':
                setFirstName(value);
                break;
            case 'lastName':
                setLastName(value);
                break;
            case 'password':
                setPassword(value);
				// Validate password criteria on change
				validatePassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

    const handleBlur = (e) => {
        const { id, value } = e.target;
        let error = '';

        switch (id) {
            case 'email':
                error = validateEmail(value);
                setErrors(prev => ({ ...prev, [id]: error }));
                break;
            case 'firstName':
                error = validateFirstName(value);
                setErrors(prev => ({ ...prev, [id]: error }));
                break;
            case 'lastName':
                error = validateLastName(value);
                setErrors(prev => ({ ...prev, [id]: error }));
                break;
            case 'password':
                error = validatePassword(value);
                setErrors(prev => ({ ...prev, [id]: error }));
                break;
            case 'confirmPassword':
                error = validateConfirmPassword(value);
                setErrors(prev => ({ ...prev, [id]: error }));
                break;
            default:
                break;
        }
    };

    const handleFocus = (e) => {
		// Reset fields error on focus
        const { id } = e.target;
        setErrors(prev => ({ ...prev, [id]: '' }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Revalidate all fields on submit to capture any changes
        const newErrors = {
            email: validateEmail(email),
            firstName: validateFirstName(firstName),
            lastName: validateLastName(lastName),
            password: validatePassword(password),
            confirmPassword: validateConfirmPassword(confirmPassword),
			termsCheckbox: validateTermsCheckbox()
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error)) return; // Prevent form submission if any error exists

        try {
            const response = await api.post('/public/register', {
                email,
                first_name: firstName.trim(),
                last_name: lastName.trim(),
                password,
				language
            });
            if (response.status === 201) {
				// Handle successful registration (e.g., redirect to login page)
                navigate(loginPath);
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setEmailConflict(true); // Mark email field as conflicted
                setServerError(t('error.email_exists', { ns: 'register' }));
            } else {
                setServerError(t('error.registration_error', { ns: 'register' }));
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
            }
        }
    };

    return (<>
		<MetaTags />

		<h1>{ t('title', { ns: 'register' }) }</h1>

		<form className="form-account" onSubmit={handleSubmit} noValidate>
			<div className="form-group">
				<label htmlFor="email">{ t('form.email_label', { ns: 'user_form' }) }*</label>
				<input
					type="email"
					id="email"
					value={email}
					onChange={handleChange}
					onBlur={handleBlur}
					onFocus={handleFocus}
					required
					maxLength="254"
					className={errors.email || emailConflict ? 'error' : ''}
				/>
				{/* Only show error message if there is a specific validation error */}
				{errors.email && <p className="error">{errors.email}</p>}
			</div>

			<div className="form-group">
				<label htmlFor="firstName">{ t('form.first_name_label', { ns: 'user_form' }) }*</label>
				<input
					type="text"
					id="firstName"
					value={firstName}
					onChange={handleChange}
					onBlur={handleBlur}
					onFocus={handleFocus}
					required
					maxLength="50"
					className={errors.firstName ? 'error' : ''}
				/>
				{errors.firstName && <p className="error">{errors.firstName}</p>}
			</div>

			<div className="form-group">
				<label htmlFor="lastName">{ t('form.last_name_label', { ns: 'user_form' }) }</label>
				<input
					type="text"
					id="lastName"
					value={lastName}
					onChange={handleChange}
					onBlur={handleBlur}
					onFocus={handleFocus}
					maxLength="50"
					className={errors.lastName ? 'error' : ''}
				/>
				{errors.lastName && <p className="error">{errors.lastName}</p>}
			</div>

			<div className="form-group">
				<label htmlFor="password">{ t('form.password_label', { ns: 'user_form' }) }*</label>
				<div className="password-wrapper">
					<input
						type={showPassword ? 'text' : 'password'}
						id="password"
						value={password}
						onChange={handleChange}
						onBlur={handleBlur}
						onFocus={handleFocus}
						required
						minLength="10"
						maxLength="40"
						className={errors.password ? 'error' : ''}
					/>
					<button
						type="button"
						className="password-toggle"
						onClick={() => setShowPassword(prev => !prev)}
					>
						{showPassword ? <IoEyeOff /> : <IoEye />}
					</button>
				</div>
				<ul className="password-criteria">
					<li className={passwordCriteria.length ? 'valid' : 'invalid'}>
						{passwordCriteria.length ? <IoCheckmark /> : <IoClose />}
						{ t('form.password_criteria_length', { ns: 'user_form' }) }
					</li>
					<li className={passwordCriteria.letter ? 'valid' : 'invalid'}>
						{passwordCriteria.letter ? <IoCheckmark /> : <IoClose />}
						{ t('form.password_criteria_letter', { ns: 'user_form' }) }
					</li>
					<li className={passwordCriteria.number ? 'valid' : 'invalid'}>
						{passwordCriteria.number ? <IoCheckmark /> : <IoClose />}
						{ t('form.password_criteria_number', { ns: 'user_form' }) }
					</li>
					<li className={passwordCriteria.specialChar ? 'valid' : 'invalid'}>
						{passwordCriteria.specialChar ? <IoCheckmark /> : <IoClose />}
						{ t('form.password_criteria_special_char', { ns: 'user_form' }) }
					</li>
				</ul>
				{errors.password && <p className="error">{errors.password}</p>}
			</div>

			<div className="form-group">
				<label htmlFor="confirmPassword">{ t('form.confirm_password_label', { ns: 'user_form' }) }*</label>
				<div className="password-wrapper">
					<input
						type={showConfirmPassword ? 'text' : 'password'}
						id="confirmPassword"
						value={confirmPassword}
						onChange={handleChange}
						onBlur={handleBlur}
						onFocus={handleFocus}
						required
						className={errors.confirmPassword ? 'error' : ''}
					/>
					<button
						type="button"
						className="password-toggle"
						onClick={() => setShowConfirmPassword(prev => !prev)}
					>
						{showConfirmPassword ? <IoEyeOff /> : <IoEye />}
					</button>
				</div>
				{errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
			</div>

			<div className="form-group">
				<div id="form-terms" className={errors.termsCheckbox ? 'highlight' : ''}>
					<input
						type="checkbox"
						id="termsCheckbox"
						checked={termsAccepted}
						onChange={(e) => setTermsAccepted(e.target.checked)}
						onFocus={handleFocus}
					/>
					<label htmlFor="termsCheckbox">{ t('terms_checkbox_label', { ns: 'register' }) } <a href={termsLink} rel="noreferrer nofollow" target="_blank">{ t('footer.link_tos', { ns: 'common' }) }</a>.</label>
				</div>
				{!termsAccepted && <p className="error">{errors.termsCheckbox}</p>}
			</div>

			<div id="submit-wrapper">
				<button type="submit">{ t('link_register', { ns: 'common' }) }</button>
				{serverError && <p className="error-message">{serverError}</p>}
			</div>
		</form>
    </>);
}

RegisterPage.namespaces = ['register', 'user_form'];

export default RegisterPage;