import { AlertModal } from './AlertModal';

export const ErrorRefreshModal = ({ t = null, onClose }) => {
	const useI18n = process.env.REACT_APP_USE_I18N === 'true';
	if (useI18n && !t) {
		return null;
	}

    const message_admin = "Le renouvellement de la session a écoué. Veuillez vous reconnecter.";

    return (
		<AlertModal
			message={ useI18n ?
				t('modal.refresh_error_message', { ns: 'common' }) :
				message_admin }
			close={ useI18n ?
				t('modal.close', { ns: 'common' }) :
				"Fermer" }
			onClose={onClose}
		/>
    );
};
