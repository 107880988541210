import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { api, sanitizeFileName } from '@client/shared'

import CreatePanier from './CreatePanier';
import DeleteFavouritesModal from './DeleteFavouritesModal';
import DeletePanierModal from './DeletePanierModal';
import PlancheContact from './PlancheContact';
import SelectDropdownPanier from './SelectDropdownPanier';
import { useLanguage } from '../contexts/LanguageContext';

function Paniers({ user }) {
	const navigate = useNavigate();
	const { t } = useTranslation(['favourites']);
	const { language } = useLanguage();

	// Initialize URLSearchParams with the current query string
	let params = new URLSearchParams(window.location.search);

	const [paniers, setPaniers] = useState([]);
	const [refreshPaniers, setRefreshPaniers] = useState(false);
	const [panier, setPanier] = useState(null);
	const [images, setImages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [refreshImages, setRefreshImages] = useState(false);
	const [serverErrorPaniers, setServerErrorPaniers] = useState(false);
	const [serverErrorImages, setServerErrorImages] = useState(false);
	const [serverErrorDeleteFavourites, setServerErrorDeleteFavourites] = useState(false);
	const [serverErrorDeletePanier, setServerErrorDeletePanier] = useState('');

	const [serverErrorDownload, setServerErrorDownload] = useState('');
	const [successDownload, setSuccessDownload] = useState(false);

	// State to control the delete confirmation modals
	const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeletePanierModal, setShowDeletePanierModal] = useState(false);

	// Fetch user's list of Panier
	useEffect(() => {
		async function fetchPaniers() {
			try {
				const response = await api.get(`/partners/${user.user_id}/paniers`);

				// Handle the response
				if (response.status === 200) {
					const translatedPaniers = response.data.paniers.map(option => ({
						...option,
						nom_en: option.nom === "Favoris" ? "Favourites" : option.nom
					}));
					setPaniers(translatedPaniers);

					// Fetch panier_id from query parameters and set the panier if found
                    const panierIdFromParams = params.get('panier_id');
                    if (panierIdFromParams) {
                        const foundPanier = translatedPaniers.find(p => p.panier_id === parseInt(panierIdFromParams, 10));
                        if (foundPanier) {
                            setPanier(foundPanier);
                        }
                    }
				}
			} catch (error) {
				setServerErrorPaniers(true);
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				} else {
					console.error("Error while fetching user's list of Paniers:", error);
				}
			}
		}

		if (user) {
			fetchPaniers();
		}
		// eslint-disable-next-line
	}, [user, refreshPaniers]);

	// Fetch selected Panier and update URL with panier_id
	useEffect(() => {
		async function fetchImages() {
			try {
				const response = await api.get(`/partners/${user.user_id}/paniers/${panier.panier_id}`);

				// Handle the response
				if (response.status === 200) {
					setImages(response.data.images);
					setLoading(false);
				}
			} catch (error) {
				setServerErrorImages(true);
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				} else {
					console.error("Error while fetching user's selected panier:", error);
				}
				setLoading(false);
			}
		}

		setLoading(true);
        if (user && panier && panier.panier_id) {
			// Reset pagination
            params.set('page', 1);

            // Add panier_id to query parameters
            params.set('panier_id', panier.panier_id);

            // Navigate with updated query string
            navigate(`?${params.toString()}`);

			fetchImages();
		}
		// eslint-disable-next-line
    }, [panier, refreshImages]);

	const handleDelete = () => {
		setServerErrorDeleteFavourites(false);
		setShowDeleteModal(true); // Show confirmation modal
	}

	const handleConfirmDelete = async () => {
        try {
            const response = await api.delete(`/users/${user.user_id}/favourites`);
            if (response.status === 200) {
                setPanier(null);
                // Empty images array
				setImages([]);
            }
        } catch (error) {
			setServerErrorDeleteFavourites(true);
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
        } finally {
            setShowDeleteModal(false); // Hide confirmation modal
        }
    };

	const handleCancelDelete = () => {
        setShowDeleteModal(false); // Hide confirmation modal
    };

	const handleDeletePanier = () => {
		if (panier.nom === "Favoris") {
			setServerErrorDeletePanier(t('error.forbid_favourites', { ns: 'favourites' }))
		}
		setServerErrorDeletePanier('');
		setShowDeletePanierModal(true); // Show confirmation modal
	}

	const handleConfirmDeletePanier = async () => {
        try {
            const response = await api.delete(`/partners/${user.user_id}/paniers/${panier.panier_id}`);
            if (response.status === 200) {
				setPanier(null);
                // Empty images array
				setImages([]);
				setRefreshPaniers((prev) => !prev);
            }
        } catch (error) {
			setServerErrorDeletePanier(t('error.delete_panier', { ns: 'favourites' }));
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
        } finally {
            setShowDeletePanierModal(false); // Hide confirmation modal
        }
    };

	const handleCancelDeletePanier = () => {
        setShowDeletePanierModal(false); // Hide confirmation modal
    };

	const handleDownloadPanier = async () => {
		setServerErrorDownload('');
		setSuccessDownload(false);
		try {
			const response = await api.get(`/partners/${user.user_id}/paniers/${panier.panier_id}/download`, {
				responseType: 'blob'  // Ensure the response is treated as a binary large object (for files)
			});
	
			// Handle the response
			if (response.status === 200) {
				setSuccessDownload(true);
	
				// Create a link element, set the URL to the blob, and trigger a click to download the file
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				// Name of the ZIP file
				const zip_name = language === "fr" ?
					sanitizeFileName(panier.nom) :
					sanitizeFileName(panier.nom_en);
				link.setAttribute('download', `${zip_name}.zip`);
				document.body.appendChild(link);
				link.click();
	
				// Clean up the URL object
				window.URL.revokeObjectURL(url);
			}
		} catch (error) {
			if (error.response && error.response.status === 403) {
				setServerErrorDownload(t('error.download_too_many', { ns: 'favourites' }));
			} else {
				setServerErrorDownload(t('error.download', { ns: 'favourites' }));
				if (error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				} else {
					console.error("Error while creating zip of selected panier:", error);
				}
			}
		}
	}	

	if (!paniers || paniers.length === 0) {
        return (<div>
			{serverErrorPaniers ?
			<p className="error-message">{ t('error.fetch_paniers', { ns: 'favourites' }) }</p> :
			<p>{ t('loading', { ns: 'common' }) }</p>}
		</div>);
    }

	return (<>
		<div>
			<h2>{ t('creer_panier', { ns: 'favourites' }) }</h2>

			<CreatePanier user={user} nbPaniers={paniers.length} setRefresh={setRefreshPaniers} />
		</div>

		<div>
			<h2>{ t('consulter_panier', { ns: 'favourites' }) }</h2>
		
			<form id="panier-form" noValidate>
				<div className="form-group">
					<label htmlFor="panier">{t('champs.panier', { ns: 'common' })}</label>
					<SelectDropdownPanier
						id="panier"
						options={paniers}
						onChange={setPanier}
						value={panier}
						placeholder={t('champs.select', { ns: 'common' })}
					/>
				</div>
			</form>

			{panier && images &&
			<div id="action-buttons">
				{panier.nom === "Favoris" && images.length > 0 &&
				<div className="action-button">
					<button onClick={handleDelete}>{ t('delete_all', { ns: 'favourites' }) }</button>
					{serverErrorDeleteFavourites && <p className="error-message">{ t('error.delete_favourites', { ns: 'favourites' }) }</p>}
				</div>}

				{panier.nom !== "Favoris" &&
				<div className="action-button">
					<button onClick={handleDeletePanier}>{ t('delete_panier', { ns: 'favourites' }) }</button>
					{serverErrorDeletePanier && <p className="error-message">{serverErrorDeletePanier}</p>}
				</div>}

				{images.length > 0 &&
				<div className="action-button">
					<button onClick={handleDownloadPanier}>{ t('download', { ns: 'favourites' }) }</button>
				</div>}
			</div>}

			<div className="server-message">
				{serverErrorDownload && <p className="error-message">{serverErrorDownload}</p>}
				{successDownload && <p className="success-message">{ t('download_success', { ns: 'favourites' }) }</p>}
			</div>
			
			{panier &&
			<div id="favourites-results">
				{serverErrorImages ?
				<p className="error-message">{ t('error.fetch_panier', { ns: 'favourites' }) }</p> :
				<PlancheContact results={images} loading={loading} no_results={t('no_results_panier', { ns: 'favourites' })} panier_id={panier.panier_id} setRefresh={setRefreshImages} user_id={user.user_id} />}
			</div>}
		</div>

		{showDeleteModal && (
            <DeleteFavouritesModal
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}

		{showDeletePanierModal && (
            <DeletePanierModal
                onConfirm={handleConfirmDeletePanier}
                onCancel={handleCancelDeletePanier}
            />
        )}
	</>);
}

export default Paniers;