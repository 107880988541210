import { logoVDI_thumbnail } from '@client/shared';

export const Loader = () => {
	return (
		<div className="loader">
			<img src={logoVDI_thumbnail} alt="Logo" />
			<div>En chargement…</div>
			<div>Loading…</div>
		</div>
	);
}