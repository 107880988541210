import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '@client/shared';

import DeleteFavouritesModal from './DeleteFavouritesModal';
import PlancheContact from './PlancheContact';

function Favourites({ user }) {
	const { t } = useTranslation(['favourites']);

	const [favourites, setFavourites] = useState([]);
	const [panierId, setPanierId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [serverError, setServerError] = useState(false);
	const [serverErrorDelete, setServerErrorDelete] = useState(false);

	// State to control the delete confirmation modal
    const [showDeleteModal, setShowDeleteModal] = useState(false);

	// Fetch user's Panier "Favoris" from the DB
	useEffect(() => {
		async function fetchFavourites() {
			try {
				const response = await api.get(`/users/${user.user_id}/favourites`);

				// Handle the response
				if (response.status === 200) {
					setFavourites(response.data.favourites);
					setPanierId(response.data.panier_id);
					setLoading(false);
				}
			} catch (error) {
				setServerError(true);
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				} else {
					console.error("Error while fetching user's favourites:", error);
				}
				setLoading(false);
			}
		}

		setLoading(true);
        if (user) {
			fetchFavourites();
		}
		// eslint-disable-next-line
    }, [user, refresh]);

	const handleDelete = () => {
		setServerErrorDelete(false);
		setShowDeleteModal(true); // Show confirmation modal
	}

	const handleConfirmDelete = async () => {
        try {
            const response = await api.delete(`/users/${user.user_id}/favourites`);
            if (response.status === 200) {
                // Empty favourites array
				setFavourites([]);
            }
        } catch (error) {
			setServerErrorDelete(true);
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
        } finally {
            setShowDeleteModal(false); // Hide confirmation modal
        }
    };

	const handleCancelDelete = () => {
        setShowDeleteModal(false); // Hide confirmation modal
    };

	if (!favourites || !panierId) {
        return (
			serverError && <p className="error-message">{ t('error.fetch_favourites', { ns: 'favourites' }) }</p>
		);
    }

	return (<>
		{favourites && favourites.length > 0 &&
		<div className="action-button">
			<button onClick={handleDelete}>{ t('delete_all', { ns: 'favourites' }) }</button>
			{serverErrorDelete && <p className="error-message">{ t('error.delete_favourites', { ns: 'favourites' }) }</p>}
		</div>}
		
		<div id="favourites-results">
			<PlancheContact results={favourites} loading={loading} no_results={t('no_results', { ns: 'favourites' })} panier_id={panierId} setRefresh={setRefresh} user_id={user.user_id} />
		</div>

		{showDeleteModal && (
            <DeleteFavouritesModal
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
	</>);
}

export default Favourites;