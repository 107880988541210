import { forwardRef, useImperativeHandle, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';

import {
	AutoCompleteDropdown,
	processDateParution,
	validateDateParution
} from '@client/shared';

import { useLanguage } from '../contexts/LanguageContext';

const SourceDateParution = forwardRef(({ id, label, value, onChange, options, annee = null }, ref) => {
	const { language } = useLanguage();

	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
	}

	const validate = () => {
		let validation = validateDateParution(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	const handleFocus = (e) => {
		setError(false);
	}

	const remove = () => {
        onChange('');
    };

	let filteredOptions = options;
	if (annee) {
		filteredOptions = options.filter(option => option.includes(annee));
	}

	let processedOptions = [];
	if (filteredOptions.length > 0) {
		processedOptions = filteredOptions.map(option => ({
			id: option,
			nom: processDateParution(option, language)
		}));
	}

	if (processedOptions.length === 0) {
		return (<div className="form-group source-champ">
			<label htmlFor={id} id="source-date_parution" className="source-champ-nom">{label}</label>
			<input
				type="text"
				id={id}
				value={value}
				onChange={onChange}
				className={`source-champ-input ${error ? "error" : ""}`}
				disabled
			/>
			<button type="button" className="remove-button"><IoCloseCircleOutline /></button>
		</div>);
	}

    return (<div className="form-group source-champ">
		<label htmlFor={id} className="source-champ-nom">{label}</label>
        <AutoCompleteDropdown
			id={id}
			value={value}
			onChange={onChange}
			onFocus={handleFocus}
			options={processedOptions}
			maxLength="50"
			className={`source-champ-input ${error ? "error" : ""}`}
		/>
		<button type="button" onClick={remove} className="remove-button"><IoCloseCircleOutline /></button>
    </div>);
});

export default SourceDateParution;