import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoClose, IoAddOutline } from 'react-icons/io5';

import {
	ChampAnnee,
	ChampCategorie,
	ChampDateParution,
	ChampDecennie,
	ChampId,
	ChampSousCategorie,
	ChampText,
	api,
	displayWidth,
	scrollToTop,
	validateTextFormat
} from '@client/shared'
// import { VDI_Array } from '@client/shared';
import '@client/shared/src/stylesheets/form.css';

import { useLanguage } from '../contexts/LanguageContext';
import { useThesaurusContext } from '../contexts/ThesaurusContext';

function SearchForm(props) {
	// props.setResults to send search results Array to SearchPage
	const navigate = useNavigate();
	const { t } = useTranslation(['search']);
	const { language } = useLanguage();
	const { thesaurus, maxTables } = useThesaurusContext();

	// Initialize URLSearchParams with the current query string
	let params = new URLSearchParams(window.location.search);

	const widthThreshold = displayWidth.medium;
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [showForm, setShowForm] = useState(window.innerWidth >= widthThreshold);

	// Update custom window.innerWidth state = windowWidth
	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
	
		window.addEventListener('resize', handleResize);
	
		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	// Always set the form to visible for medium/wide display
	useEffect(() => {
		if (windowWidth >= widthThreshold) {
			setShowForm(true);
		}
    }, [windowWidth, widthThreshold]);

	const closeFormOnMobile = () => {
		if (windowWidth < widthThreshold) {
			setShowForm(false);
		}
	};

	const toggleForm = () => {
		setShowForm(!showForm);
	};

	const closeButton = (<>
		<div id="form-close" onClick={toggleForm}>
			<IoClose />
		</div>
	</>);

	const openButton = (<>
		<div id="form-open" onClick={toggleForm}>
			<IoAddOutline />
		</div>
	</>);

	const numberOfFields = 13; // Number of fields in your form

	// fields states
	const [codeImage, setCodeImage] = useState('');
	const [source, setSource] = useState('');
	const [dateParution, setDateParution] = useState('');
	const [categorie, setCategorie] = useState('');
	const [sousCategorie, setSousCategorie] = useState('');
	const [legende, setLegende] = useState('');
	const [motCle, setMotCle] = useState('');
	const [style, setStyle] = useState('');
	const [decennie, setDecennie] = useState('');
	const [annee, setAnnee] = useState('');
	const [couturier, setCouturier] = useState('');
	const [designer, setDesigner] = useState('');
	const [coloris, setColoris] = useState('');

	// fields refs to trigger validation onSubmit
	const fieldRefs = useRef(
        Array.from({ length: numberOfFields }, () => React.createRef())
    );

	// link fields together
	const [isPeriodique, setIsPeriodique] = useState(false);

	// additional check to avoid too broad requests
	const [codeImageConflicted, setCodeImageConflicted] = useState(false);
	const [legendeConflicted, setLegendeConflicted] = useState(false);

	// other submit errors
	const [previousQuery, setPreviousQuery] = useState('');
	const [serverError, setServerError] = useState('');

	// Make the request JSON with all fields value
	const getRequestJSON = () => {
		return {
			codeImage,
			source,
			dateParution,
			categorie,
			sousCategorie,
			legende,
			motCle,
			style,
			decennie,
			annee,
			couturier,
			designer,
			coloris
		};
	}

	// Send the search request
	const makeSearchRequest = async () => {
		props.setLoading(true);
		const request = getRequestJSON();
		try {
			const response = await api.get("/medias/search", {
                params: request
            });

			// Handle the response
			if (response.status === 200) {
				scrollToTop();
				props.setResults(response.data.results);
				props.setLoading(false);
				closeFormOnMobile();

				// Update previousQuery
				setPreviousQuery(JSON.stringify(request));
			}
		} catch (error) {
			setServerError(t('error.server_error', { ns: 'search' }));
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			} else {
				console.error("Error while making search request:", error);
			}
			props.setLoading(false);
		}
		// test
		/*
		props.setResults(VDI_Array);
		props.setLoading(false);
		*/
	}

	// Search for filters in the query parameters
	const [initialRender, setInitialRender] = useState(true);	
	useLayoutEffect(() => {
		// Ensure Asynchronous Data is Loaded
		if (!thesaurus || !maxTables) return; // Ensure they are available

		let param;
		let id;

		const processParam = (stringParam, table) => {
			const id = parseInt(stringParam, 10);
			if (id > 0 && id <= maxTables[table]) {
				return id;
			}
			return null;
		}

		const codeImageParam = params.get('code_image');
		if (codeImageParam) {
			if (codeImageParam !== "VDI" && !codeImageParam.match(/^-?PH\d{2}$/)) {
				setCodeImage(codeImageParam);
			}
		}

		param = params.get('source');
		if (param) {
			const sourceId = processParam(param, "Sources");
			if (sourceId) {
				setSource(sourceId);

				// check for date_parution only if source is Périodique
				const dateParutionParam = params.get('date_parution');
				if (dateParutionParam &&
					thesaurus.date_parution.some(item => item.id === dateParutionParam)) {
					const selectedSource = thesaurus.sources.find(option => option.id === sourceId);
					if (selectedSource.type_publication === "Périodique") {
						setIsPeriodique(true);
						setDateParution(dateParutionParam);
					}
				}
			}
		}
	
		param = params.get('categorie');
		if (param) {
			id = processParam(param, "Categories");
			if (id) {
				setCategorie(id);

				// check for sous_categorie only if categorie is valid
				param = params.get('sous_categorie');
				if (param) {
					id = processParam(param, "Sous_categories");
					if (id) {
						setSousCategorie(id);
					}
				}
			}
		}
		
		const paramLegende = params.get('legende');
		if (paramLegende && validateTextFormat(paramLegende)) {
			setLegende(paramLegende);

		}
	
		param = params.get('mot_cle');
		if (param) {
			id = processParam(param, "Mots_cles");
			if (id) {
				setMotCle(id)
			}
		}
	
		param = params.get('style');
		if (param) {
			id = processParam(param, "Styles");
			if (id) {
				setStyle(id)
			}
		}
	
		const decennieParam = params.get('decennie');
		if (decennieParam) {
			const decennieInt = parseInt(decennieParam, 10);
			if (thesaurus.decennies.includes(decennieInt)) {
				setDecennie(decennieInt);
			}
		}
	
		const anneeParam = params.get('annee');
		if (anneeParam && thesaurus.annees.includes(anneeParam)) {
			setAnnee(anneeParam);
		}

		param = params.get('couturier');
		if (param) {
			id = processParam(param, "Couturiers");
			if (id) {
				setCouturier(id)
			}
		}

		param = params.get('designer');
		if (param) {
			id = processParam(param, "Designers_illustrateurs");
			if (id) {
				setDesigner(id)
			}
		}

		param = params.get('coloris');
		if (param) {
			id = processParam(param, "Coloris");
			if (id) {
				setColoris(id)
			}
		}

		if (!window.location.search || window.location.search === '?') {
            setInitialRender(false);
        }

		/* I didn't add params to the dependencies array to prevent useLayoutEffect
		from running when I'm going to change the query parameters to match the SearchForm
		fields values on submit. */
		// eslint-disable-next-line
	}, [thesaurus, maxTables]);

	// Separately call makeSearchRequest to avoid race condition between all the set{State} in useLayoutEffect and makeSearchRequest
	useEffect(() => {
		// Ensure all relevant fields have been set
		if (initialRender && (
			(params.get('code_image') && codeImage) ||
			(params.get('source') && source) ||
			(params.get('date_parution') && dateParution) ||
			(params.get('categorie') && categorie) ||
			(params.get('sous_categorie') && sousCategorie) ||
			(params.get('legende') && legende) ||
			(params.get('mot_cle') && motCle) ||
			(params.get('style') && style) ||
			(params.get('decennie') && decennie) ||
			(params.get('annee') && annee) ||
			(params.get('couturier') && couturier) ||
			(params.get('designer') && designer) ||
			(params.get('coloris') && coloris))) {
			setInitialRender(false);
			makeSearchRequest();
		}
		// eslint-disable-next-line
	}, [codeImage, source, dateParution, categorie, sousCategorie, legende, motCle, style, decennie, annee, couturier, designer, coloris]);	

	// Reset handler to clear all fields
    const handleReset = () => {
        // Iterate over the refs and to trigger reset
		scrollToTop();
        fieldRefs.current.forEach((ref) => ref.current.reset());
        setServerError(null); // Clear any server errors if any
    };

	const handleSubmit = async (event) => {
		event.preventDefault();
		setServerError(null);

		// Compare previousQuery and new query
		const newQuery = JSON.stringify(getRequestJSON());

		// Compare with the previous query
		if (previousQuery === newQuery) {
			setServerError(t('error.request_not_changed', { ns: 'search' }));
			return; // Prevent form submission
		}
	
		let validForm = true;

        // Iterate over the refs and validate each field
        fieldRefs.current.forEach((ref) => {
            if (ref.current && !ref.current.validate()) {
                validForm = false;
            }
        });

		// Prevent form submission if any error exists
        if (!validForm) {
            return;
        }
	
		// Check if any field has a value
		if (!(codeImage || source || dateParution || categorie || sousCategorie || legende || motCle || style || decennie || annee || couturier || designer || coloris)) {
			setServerError(t('error.no_fields_filled', { ns: 'search' }));
			return;  // Prevent form submission
		}

		// Additional check for codeImage to avoid too broad requests
		if (codeImage === "VDI" || codeImage.match(/^-?PH\d{2}$/)) {
			setServerError(t('error.code_image_too_broad', { ns: 'search' }));
			setCodeImageConflicted(true);
			return;  // Prevent form submission
		}
	
		// Additional check for legende to avoid too broad requests
		if (legende.length > 0 && legende.length < 5) {
			setServerError(t('error.legende_too_broad', { ns: 'search' }));
			setLegendeConflicted(true);
			return;  // Prevent form submission
		}

		// Clear existing query parameters
		params = new URLSearchParams(); // This effectively clears all previous parameters

		// Add parameters to the query string if they are not empty or null
		if (codeImage) params.set('code_image', codeImage);
		if (source) params.set('source', source);
		if (dateParution) params.set('date_parution', dateParution);
		if (categorie) params.set('categorie', categorie);
		if (sousCategorie) params.set('sous_categorie', sousCategorie);
		if (legende) params.set('legende', legende);
		if (motCle) params.set('mot_cle', motCle);
		if (style) params.set('style', style);
		if (decennie) params.set('decennie', decennie);
		if (annee) params.set('annee', annee);
		if (couturier) params.set('couturier', couturier);
		if (designer) params.set('designer', designer);
		if (coloris) params.set('coloris', coloris);
	
		// Set the 'page' parameter to 1 to reset pagination
		params.set('page', '1');

		// Navigate to the search page with the constructed query string
		navigate(`/search?${params.toString()}`);

		// Make the search request
		makeSearchRequest();
	};

	return (<>
		<div className="search-form">
		<h2>{t('form.title', { ns: 'search' })}</h2>
		{showForm ? closeButton : openButton}
		{showForm && (<>
		<form onSubmit={handleSubmit} onReset={handleReset} noValidate ref={props.searchFormRef}>
			<ChampText
				id="codeImage"
				label={t('champs.code_image', { ns: 'common' })}
				value={codeImage}
				onChange={setCodeImage}
				maxLength="30"
				errorMessage={t('error.field_format', { ns: 'search' })}
				isConflicted={codeImageConflicted}
				setIsConflicted={setCodeImageConflicted}
				ref={fieldRefs.current[0]}
			/>

			<ChampId
				id="source"
				label={t('champs.source', { ns: 'common' })}
				value={source}
				onChange={setSource}
				options={thesaurus.sources}
				maxLength="250"
				errorMessage={t('error.field_format', { ns: 'search' })}
				setIsPeriodique={setIsPeriodique}
				setDateParution={setDateParution}
				ref={fieldRefs.current[1]}
			/>
			
			<ChampDateParution
				language={language}
				id="dateParution"
				label={t('champs.date_parution', { ns: 'common' }) + t('form.date_parution_label', { ns: 'search' })}
				value={dateParution}
				onChange={setDateParution}
				options={thesaurus.date_parution}
				isPeriodique={isPeriodique}
				source_id={source}
				ref={fieldRefs.current[2]}
			/>

			<ChampCategorie
				language={language}
				id="categorie"
				label={t('champs.categorie', { ns: 'common' })}
				value={categorie}
				onChange={setCategorie}
				options={thesaurus.categories}
				placeholder={t('champs.select', { ns: 'common' })}
				setSousCategorie={setSousCategorie}
				ref={fieldRefs.current[3]}
			/>

			<ChampSousCategorie
				language={language}
				id="sousCategorie"
				label={t('champs.sous_categorie', { ns: 'common' })}
				value={sousCategorie}
				onChange={setSousCategorie}
				options={thesaurus.sousCategories}
				placeholder={t('champs.select', { ns: 'common' })}
				categorie={categorie}
				setCategorie={setCategorie}
				ref={fieldRefs.current[4]}
			/>

			<ChampText
				id="legende"
				label={t('champs.legende', { ns: 'common' })}
				value={legende}
				onChange={setLegende}
				maxLength="50"
				isConflicted={legendeConflicted}
				setIsConflicted={setLegendeConflicted}
				ref={fieldRefs.current[5]}
			/>

			<ChampId
				language={language}
				id="motCle"
				label={t('champs.mots_cles', { ns: 'common' })}
				value={motCle}
				onChange={setMotCle}
				options={thesaurus.motsCles}
				maxLength="60"
				ref={fieldRefs.current[6]}
			/>

			<ChampId
				language={language}
				id="style"
				label={t('champs.style', { ns: 'common' })}
				value={style}
				onChange={setStyle}
				options={thesaurus.styles}
				maxLength="50"
				ref={fieldRefs.current[7]}
			/>

			<ChampDecennie
				id="decennie"
				label={t('champs.decennie', { ns: 'common' })}
				value={decennie}
				onChange={setDecennie}
				options={thesaurus.decennies}
				errorMessage={t('error.invalid_decade', { ns: 'search' })}
				setAnnee={setAnnee}
				ref={fieldRefs.current[8]}
			/>

			<ChampAnnee
				id="annee"
				label={t('champs.annee', { ns: 'common' })}
				value={annee}
				onChange={setAnnee}
				options={thesaurus.annees}
				decennie={decennie}
				setDecennie={setDecennie}
				ref={fieldRefs.current[9]}
			/>

			<ChampId
				id="couturier"
				label={t('champs.couturier', { ns: 'common' })}
				value={couturier}
				onChange={setCouturier}
				options={thesaurus.couturiers}
				maxLength="50"
				ref={fieldRefs.current[10]}
			/>

			<ChampId
				id="designer"
				label={t('champs.designer', { ns: 'common' })}
				value={designer}
				onChange={setDesigner}
				options={thesaurus.designers}
				maxLength="50"
				ref={fieldRefs.current[11]}
			/>

			<ChampId
				language={language}
				id="coloris"
				label={t('champs.coloris', { ns: 'common' })}
				value={coloris}
				onChange={setColoris}
				options={thesaurus.coloris}
				maxLength="50"
				ref={fieldRefs.current[12]}
			/>

			<div id="submit-wrapper">
				<div id="search-buttons">
					<button type="submit">{t('form.submit', { ns: 'search' })}</button>
					<button type="reset" id="search-reset">{t('form.reset', { ns: 'search' })}</button>
				</div>
				{serverError && <p className="error-message">{serverError}</p>}
			</div>
		</form>
		<p id="list-characters">{ t('valid_special_characters', { ns: 'search' }) }</p>
		</>)}
		</div>
	</>);
}

export default SearchForm;