import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import Cookies from 'js-cookie';

import { useAuthContext, api } from '@client/shared';
import '@client/shared/src/stylesheets/form.css';

import MetaTags from './MetaTags';
import LocalizedLink from './LocalizedLink';
import { useLanguage } from '../contexts/LanguageContext';
import useLocalizedPath from '../hooks/useLocalizedPath';

import '../stylesheets/login.css';

function LoginPage() {
	const { t } = useTranslation(['login', 'user_form', 'resend_verification']);
	const location = useLocation();
	const navigate = useNavigate();
	const { language } = useLanguage();
	const { user, dispatch } = useAuthContext();
	const redirect_link = useLocalizedPath("/");
	
	// redirect connected users
    useLayoutEffect(() => {
        if (user) {
			navigate(redirect_link);
		}
		// eslint-disable-next-line
    }, [user, location.pathname, navigate]);

	const [homePath, setHomePath] = useState(language === 'fr' ? '/fr/' : '/');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState({});
	const [serverError, setServerError] = useState('');
	const [emailNotFound, setEmailNotFound] = useState(false);
	const [passwordIncorrect, setPasswordIncorrect] = useState(false);
	const [emailSuccess, setEmailSuccess] = useState(false);
	const [actionButton, setActionButton] = useState(<></>);

	// Show/Hide password state
    const [showPassword, setShowPassword] = useState(false);

	// Update content based on language
	useEffect(() => {
		setHomePath(language === 'fr' ? '/fr/' : '/');
	}, [language]);

	const validateEmail = (value) => {
        if (!value) {
            return t('error.email_empty', { ns: 'user_form' });
        } else if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            return t('error.email_format', { ns: 'user_form' });
        }
        return '';
    };

	const validatePassword = (value) => {
        if (!value) {
            return t('error.password_empty', { ns: 'user_form' });
		}
        return '';
    };

	const handleChange = (e) => {
        const { id, value } = e.target;

        switch (id) {
            case 'email':
				setActionButton(<></>);
                setEmail(value.trim());
                setEmailNotFound(false);	// Reset conflict state on change
                break;
            case 'password':
                setPassword(value);
				setPasswordIncorrect(false);	// Reset conflict state on change
                break;
            default:
                break;
        }
	}

	const handleBlur = (e) => {
		const { id, value } = e.target;
		let error = '';

		switch (id) {
            case 'email':
                error = validateEmail(value);
				setErrors(prev => ({ ...prev, [id]: error }));
                break;
            case 'password':
                error = validatePassword(value);
				setErrors(prev => ({ ...prev, [id]: error }));
                break;
            default:
                break;
        }
	};

	const handleFocus = (e) => {
		// Reset fields error on focus
		const { id } = e.target;
		setErrors(prev => ({ ...prev, [id]: '' }));
	};

	const handleResendButton = async () => {
		try {
            const response = await api.post('/public/resend_verification', {
                email,
				language
            });
			setActionButton(<></>);
			if (response.status === 200) {
				setServerError('');
				setEmailSuccess(true);
            }
        } catch (error) {
			setActionButton(<></>);
			if (error.response) {
				if (error.response.status === 400) {
					setServerError( t('error.user_not_found', { ns: 'user_form' }) );
				}
				if (error.response.status === 403) {
					// Adjust reset_time to the user's local timezone
					const reset_time = new Date(error.response.data.reset_time);
					const display_reset_time = reset_time.toLocaleString(); // Converts to local time and formats it
					const message = t('too_many', { ns: 'resend_verification' }) + display_reset_time + t('contact', { ns: 'resend_verification' });
					setServerError(message);
				}
			} else {
                setServerError( t('fail', { ns: 'resend_verification' }) );
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
            }
        }
	};

	const handleSubmit = async (event) => {
        event.preventDefault();
		setEmailSuccess(false);
        // Revalidate all fields on submit to capture any changes
        const newErrors = {
            email: validateEmail(email),
			password: validatePassword(password)
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error)) return; // Prevent form submission if any error exists

        try {
            const response = await api.post('/public/login', {
					email,
					password
			});
            if (response.status === 200) {
				// Handle successful login
				const userCookie = Cookies.get('user-data');
				const user = JSON.parse(decodeURIComponent(userCookie));

				// Update the auth context
				dispatch({
					type: 'LOGIN',
					payload: user
				});
				
				// Redirect to home page
                navigate(homePath);
            }
        } catch (error) {
			if (error.response) {
				if (error.response.status === 404) {
					setEmailNotFound(true); // Mark email field as conflicted
                	setServerError( t('error.user_not_found', { ns: 'user_form' }) );
				}
				if (error.response.status === 403) {
					setServerError( t('email_not_verified', { ns: 'resend_verification' }) );
					setActionButton(<button type="button" onClick={handleResendButton}>{ t('resend_button', { ns: 'resend_verification' }) }</button>);
				}
				if (error.response.status === 401) {
					setPasswordIncorrect(true); // Mark password field as conflicted
					setServerError( t('error.password_incorrect', { ns: 'user_form' }) );
				}
			} else {
                setServerError( t('login_error', { ns: 'login' }) );
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
            }
        }
    };

	return (<>
		<MetaTags />

		<h1>{ t('title', { ns: 'login' }) }</h1>
		<div>
			<p>{ t('text_intro', { ns: 'login' }) }</p>
		</div>

		<form className="form-account" onSubmit={handleSubmit} noValidate>
			<div className="form-group">
				<label htmlFor="email">{ t('form.email_label', { ns: 'user_form' }) }</label>
				<input
					type="email"
					id="email"
					value={email}
					onChange={handleChange}
					onBlur={handleBlur}
					onFocus={handleFocus}
					required
					maxLength="254"
					className={errors.email || emailNotFound ? 'error' : ''}
				/>
				{/* Only show error message if there is a specific validation error */}
				{errors.email && <p className="error">{errors.email}</p>}
			</div>

			<div className="form-group">
				<label htmlFor="password">{ t('form.password_label', { ns: 'user_form' }) }</label>
				<div className="password-wrapper">
					<input
						type={showPassword ? 'text' : 'password'}
						id="password"
						value={password}
						onChange={handleChange}
						onBlur={handleBlur}
						onFocus={handleFocus}
						required
						minLength="10"
						maxLength="40"
						className={errors.password || passwordIncorrect ? 'error' : ''}
					/>
					<button
						type="button"
						className="password-toggle"
						onClick={() => setShowPassword(prev => !prev)}
					>
						{showPassword ? <IoEyeOff /> : <IoEye />}
					</button>
				</div>
				{errors.password && <p className="error">{errors.password}</p>}
				<div id="forgotten-password">
					<LocalizedLink to="/forgotten_password">{ t('link_forgotten_password', { ns: 'common' }) }</LocalizedLink>
				</div>
			</div>

			<div id="submit-wrapper">
				<button type="submit">{ t('header.link_login', { ns: 'common' }) }</button>
				{serverError && <p className="error-message">{serverError}</p>}
				{actionButton}
				{emailSuccess && <p className="success-message">{ t('success', { ns: 'resend_verification' }) }</p>}
			</div>
		</form>
		<div>
			<p>{ t('text_register', { ns: 'login' }) }</p>
		</div>
		<div id="inscription">
			<LocalizedLink to="/register">{ t('link_register', { ns: 'common' }) }</LocalizedLink>
		</div>
	</>);
}

LoginPage.namespaces = ['login', 'user_form', 'resend_verification'];

export default LoginPage;