const monthNames = {
    en: {
        '01': 'January', '02': 'February', '03': 'March', '04': 'April',
        '05': 'May', '06': 'June', '07': 'July', '08': 'August',
        '09': 'September', '10': 'October', '11': 'November', '12': 'December'
    },
    fr: {
        '01': 'Janvier', '02': 'Février', '03': 'Mars', '04': 'Avril',
        '05': 'Mai', '06': 'Juin', '07': 'Juillet', '08': 'Août',
        '09': 'Septembre', '10': 'Octobre', '11': 'Novembre', '12': 'Décembre'
    }
};

export const processDateParution = (id, language) => {
    const monthNamesInLanguage = monthNames[language];

    const transformDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        if (month === '00' && day === '00') {
            return year; // YYYY-00-00 (Pattern 4)
        }
        if (day === '00') {
            return `${monthNamesInLanguage[month]} ${year}`; // YYYY-MM-00 (Pattern 5)
        }
        return `${parseInt(day)} ${monthNamesInLanguage[month]} ${year}`; // YYYY-MM-DD (Pattern 6)
    };

    // Regular expression to match the different patterns
    const regex = /(\d{4}-\d{2}-\d{2} \(ca\.\))|(\d{4}-\d{2}-\d{2} \(décennie\))|(\d{4}-\d{4})|(\d{4}-\d{2}-\d{2})/g;
    let match;
    const parts = [];

    while ((match = regex.exec(id)) !== null) {
        if (match[1]) { // YYYY-00-00 (ca.) (Pattern 1)
            parts.push(match[1].replace('-00-00 (ca.)', ' (ca.)'));
        } else if (match[2]) { // YYYY-00-00 (décennie) (Pattern 2)
            const year = match[2].split('-')[0];
            parts.push(language === 'fr' ? `Années ${year}` : `The ${year}s`);
        } else if (match[3]) { // YYYY-YYYY (Pattern 3)
            parts.push(match[3]);
        } else if (match[4]) { // YYYY-00-00, YYYY-MM-00, YYYY-MM-DD (Patterns 4, 5, 6)
            parts.push(transformDate(match[4]));
        }
    }

    if (parts.length > 1) {
        return parts.join(language === 'fr' ? ' et ' : ' and '); // Combination patterns like YYYY-MM-00 et YYYY-MM-00 (Pattern 7)
    }

    return parts.length > 0 ? parts[0] : ''; // Return the processed string or an empty string if no matches
};

export const validateAnnee = (value) => {
	if (value) {
		return value.match(/^1\d{3}(-1\d{3})?$/);
	}
	return true;
};

export const validateDateParution = (value) => {
	if (value) {
		return value.match(/^[\da-zA-ZÀ-ÿ().\s-]*$/);
	}
	return true;
};

export const validateDecennie = (value) => {
	// Check if the value is a 4-digit year ending with 0
	if (value) {
		value = String(value);
		return value.match(/^1\d{2}0$/);
	}
	return true;
};

export const validateIdFormat = (value) => {
	if (value) {
		value = String(value);
		return value.match(/^\d*$/);
	}
	return true;
}

export const validateTextFormat = (value) => {
	if (value) {
		return value.match(/^[\da-zA-ZÀ-ÿ'–&(),.:;°\s-]*$/);
	}
	return true;
};