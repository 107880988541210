import { forwardRef, useImperativeHandle, useState } from 'react';

import { validateTextFormat } from '../utils/champsFormat';

export const ChampText = forwardRef(({ id, label, value, onChange, maxLength = "50", errorMessage = "Format invalide", isConflicted = false, setIsConflicted = null }, ref) => {
	// id = unique id of the fied
	// value = field state
	// onChange = change field state
	// maxLength = taille max de value
	const [error, setError] = useState(false);

	const reset = () => {
		onChange('');
		setError(false);
		if (isConflicted) {
			setIsConflicted(false);
		}
	}

	const validate = () => {
		let validation = validateTextFormat(value);
		setError(!validation);
		return validation;
	}

	useImperativeHandle(ref, () => ({
		reset,
        validate,
    }));

	const handleChange = (e) => {
		const { value } = e.target;
		onChange(value);
		if (setIsConflicted) {
			setIsConflicted(false);
		}
	}

	const handleBlur = (e) => {
		const { value } = e.target;
		onChange(value.trim());
		let error = !validateTextFormat(value);
		setError(error);
	}

	const handleFocus = (e) => {
		setError(false);
	}

	return (<div className="form-group">
		<label htmlFor={id}>{label}</label>
        <input
			type="text"
			id={id}
			value={value}
			onChange={handleChange}
			onBlur={handleBlur}
			onFocus={handleFocus}
			maxLength={maxLength}
			className={(error || isConflicted) ? "error" : ""}
		/>
		{error && <p className="error">{errorMessage}</p>}
    </div>);
});