import { createContext, useReducer, useEffect, useLayoutEffect, useState } from 'react';
import Cookies from 'js-cookie';

import { RateLimitModal } from '../components/RateLimitModal';
import { SessionExpiryModal } from '../components/SessionExpiryModal';
import { ErrorRefreshModal } from '../components/ErrorRefreshModal';
import { api, setupInterceptors } from '../utils/api';

export const AuthContext = createContext();

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload }
        case 'LOGOUT':
            return { ...state, user: null }
        case 'SET_LOADING':
            return { ...state, authLoading: action.payload }
        default:
            return state
    }
}

// Function to validate user data
const isValidUser = (user) => {
	if (!user) return false;
	return (
		typeof user.user_id === 'number' && user.user_id > 0 &&
		typeof user.name === 'string' &&
		typeof user.role === 'number'
	);
};

// App wrapper to provide the context
export const AuthContextProvider = ({ children, t }) => {
	const [state, dispatch] = useReducer(
		authReducer,
		{ user: null, authLoading: true } // Initial state with authLoading set to true
	);

	const [showExpiryModal, setShowExpiryModal] = useState(false);
	const [showRateLimitModal, setShowRateLimitModal] = useState(false);
    const [retryAfter, setRetryAfter] = useState(null);
	const [showErrorRefreshModal, setShowErrorRefreshModal] = useState(false);

	useLayoutEffect(() => {
		const checkAuth = async (user) => {
			try {
				const response = await api.post('/users/checkAuth');
				if (response.status === 200) {
					// Handle successful authentification = update the auth context
					dispatch({
						type: 'LOGIN',
						payload: user
					});
				}
			} catch (error) {
				if (error.response && error.response.status === 401) {
					console.log("Authentication failed: User data removed from cookies");
				} else {
					console.log("Error while checking prior authentification");
				}
			} finally {
				// Set authLoading to false after check is complete
				dispatch({ type: 'SET_LOADING', payload: false });
			}
		}

		// Get the user data from the cookie
        const userCookie = Cookies.get('user-data');
		let user = null;

		if (userCookie) {
			user = JSON.parse(decodeURIComponent(userCookie));
			if (!isValidUser(user)) {
				user = null;
			}
		}

		// If no user is present, immediately set authLoading to false
		if (!user) {
			dispatch({ type: 'SET_LOADING', payload: false });
		}

		// Setup interceptors and check authentication once interceptors are ready
		setupInterceptors(dispatch, setShowRateLimitModal, setRetryAfter)
			.then(() => {
				if (user) {
					checkAuth(user);
				}
			});
	}, []);

	const handleConfirm = async () => {
		setShowExpiryModal(false);

		try {
			const response = await api.post('/users/refreshSession');

			if (response.status === 200) {
				// Refresh the user info in case the state/localStorage was tampered with
				const userCookie = Cookies.get('user-data');
				const updatedUser = JSON.parse(decodeURIComponent(userCookie));

				// Update the auth context
				dispatch({
					type: 'LOGIN',
					payload: updatedUser
				});
			}			
		} catch (error) {
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);

			}
			setShowErrorRefreshModal(true);

			// Request the server to clear the authentication cookies
			try {
				await api.post('/users/logout'); // This will clear the cookie on the server-side
			} catch (logoutError) {
				console.error('Failed to logout and clear the cookie:', logoutError);
			}
		}
	};

	const handleCancel = async () => {
		setShowExpiryModal(false);

		try {
			await api.post('/users/logout');
		} catch (error) {
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
		}

		// Update the auth context = dispatch logout action
		dispatch({ type: 'LOGOUT' });

		// Retrieve user language from localStorage
		const userLanguage = localStorage.getItem('userLanguage') || 'en';
                        
		// Determine the path prefix based on the language
		const languagePrefix = userLanguage === 'fr' ? '/fr' : '';
		
		// Navigate to the home page
		window.location.assign(`${languagePrefix}/`);
	};

	// Handle session expiry
	useEffect(() => {
		let interval;

		const handleSessionExpiry = async (user) => {
			if (!user || !user.session_expires_at) return;

			const currentTime = Date.now();
			const timeLeft = user.session_expires_at - currentTime;
			const warningTime = 5 * 60 * 1000; // 5 minutes

			if (timeLeft <= warningTime) {
				setShowExpiryModal(true);
			}
		};

		const handleExpiryAndSetInterval = async () => {
			await handleSessionExpiry(state.user);
			interval = setInterval(async () => await handleSessionExpiry(state.user), 60000); // Check every minute
		};

		if (state.user) {
			handleExpiryAndSetInterval();
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [state.user]);
	

	return (
		<AuthContext.Provider value={{...state, dispatch}}>
			{children}
			{showExpiryModal && (
				<SessionExpiryModal
					t = {t}
					onConfirm={handleConfirm}
					onCancel={handleCancel}
				/>
			)}
			{showErrorRefreshModal && (
                <ErrorRefreshModal
					t = {t}
					onClose={() => {
						// Retrieve user language from localStorage
						const userLanguage = localStorage.getItem('userLanguage') || 'en';
									
						// Determine the path prefix based on the language
						const languagePrefix = userLanguage === 'fr' ? '/fr' : '';

						setShowErrorRefreshModal(false);

						dispatch({ type: 'LOGOUT' });
						
						// Navigate to the login page
						window.location.assign(`${languagePrefix}/login`);						
					}}
				/>
            )}
			{showRateLimitModal && (
                <RateLimitModal
					t = {t}
					retryAfter={retryAfter}
					onClose={() => setShowRateLimitModal(false)}
				/>
            )}
		</AuthContext.Provider>
	)
}