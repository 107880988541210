import { useAuthContext } from '../hooks/useAuthContext';
import { api } from '../utils/api';

export const useLogout = () => {
	const { dispatch } = useAuthContext();

	const logout = async () => {
		// invalidate token server side
		try {
			await api.post('/users/logout');
		} catch (err) {
			console.log(`Error while logging out the user server side: ${err.message}`);
		}

		// update the auth context = dispatch logout action
		dispatch({
			type: 'LOGOUT'
		});
	};

	return {logout};
}