import { AlertModal } from './AlertModal';

export const RateLimitModal = ({ t = null, retryAfter, onClose }) => {
	const useI18n = process.env.REACT_APP_USE_I18N === 'true';
	if (useI18n && !t) {
		return null;
	}

    // Convert seconds to minutes and seconds
    const minutes = Math.floor(retryAfter / 60);
    const seconds = retryAfter % 60;
	const time = (minutes > 0 && `${minutes} min `) + `${seconds} s`;

	const message_admin = `Vous avez dépassé le nombre de requêtes autorisées. Veuillez réessayer dans\u00A0:\n${time}`;

    return (
		<AlertModal
			message={ useI18n ?
				t('modal.rate_limit_message', { retryAfter: time, ns: 'common' }) :
				message_admin }
			close={ useI18n ?
				t('modal.close', { ns: 'common' }) :
				"Fermer" }
			onClose={onClose}
		/>
    );
};
