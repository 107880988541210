import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoCheckmark, IoClose, IoEye, IoEyeOff } from 'react-icons/io5';

import { useAuthContext, api } from '@client/shared';
import '@client/shared/src/stylesheets/form.css';

import MetaTags from './MetaTags';
import LocalizedLink from './LocalizedLink';
import useLocalizedPath from '../hooks/useLocalizedPath';

function ResetPasswordPage() {
	const { user_id, unique_string } = useParams();
	const { t } = useTranslation(['reset_password', 'user_form']);
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuthContext();
	const redirect_link = useLocalizedPath("/");

	// redirect connected users
    useLayoutEffect(() => {
        if (user) {
			navigate(redirect_link);
		}
		// eslint-disable-next-line
    }, [user, location.pathname, navigate]);

	const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
	const [errors, setErrors] = useState({});
    const [passwordCriteria, setPasswordCriteria] = useState({
        length: false,
        letter: false,
        number: false,
        specialChar: false,
        match: false
    });
	const [serverError, setServerError] = useState('');
	const [actionButton, setActionButton] = useState(<></>);
	const [requestSuccess, setRequestSuccess] = useState(false);

	// Show/Hide password state
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const checkPasswordCriteria = (value) => {
		const criteria = {
			length: value.length >= 10 && value.length <= 40,
			letter: /[a-zA-Z]/.test(value),
			number: /[0-9]/.test(value),
			specialChar: /[!@#$%^&*()_+{}[\]:;"'<>,.?~`|\\]/.test(value),
		};
		setPasswordCriteria(criteria);
		return criteria;
	};
	
	const validatePassword = (value) => {
		const criteria = checkPasswordCriteria(value);
		if (Object.values(criteria).includes(false)) {
			return t('error.password_format', { ns: 'user_form' });
		}
		return '';
	};

    const validateConfirmPassword = (value) => {
        if (value !== password) {
            return t('error.password_mismatch', { ns: 'user_form' });
        }
        return '';
    };

	const handleChange = (e) => {
        const { id, value } = e.target;

        switch (id) {
            case 'password':
                setPassword(value);
				// Validate password criteria on change
				validatePassword(value);
                break;
            case 'confirmPassword':
                setConfirmPassword(value);
                break;
            default:
                break;
        }
    };

	const handleBlur = (e) => {
        const { id, value } = e.target;
        let error = '';

        switch (id) {
            case 'password':
                error = validatePassword(value);
                setErrors(prev => ({ ...prev, [id]: error }));
                break;
            case 'confirmPassword':
                error = validateConfirmPassword(value);
                setErrors(prev => ({ ...prev, [id]: error }));
                break;
            default:
                break;
        }
    };

	const handleFocus = (e) => {
		// Reset fields error on focus
        const { id } = e.target;
        setErrors(prev => ({ ...prev, [id]: '' }));
    };

	const forgotten_password = (<>
		<div id="inscription"><LocalizedLink to="/forgotten_password">{ t('link_forgotten_password', { ns: 'common' }) }</LocalizedLink></div>
	</>);

	const handleSubmit = async (event) => {
        event.preventDefault();
		setActionButton(<></>);
		setRequestSuccess(false);
        // Revalidate all fields on submit to capture any changes
        const newErrors = {
            password: validatePassword(password),
            confirmPassword: validateConfirmPassword(confirmPassword)
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error)) return; // Prevent form submission if any error exists

        try {
            const response = await api.patch('/public/reset_password', {
                user_id,
				unique_string,
				new_password: password
            });
            if (response.status === 200) {
				setServerError('');
				setRequestSuccess(true);
				setActionButton(<div id="inscription"><LocalizedLink to="/login">{ t('header.link_login', { ns: 'common' }) }</LocalizedLink></div>);
            }
        } catch (error) {
			if (error.response) {
				if (error.response.status === 404) {
					setServerError(t('error.reset_not_found', { ns: 'reset_password' }));
					setActionButton(forgotten_password);
				}
				if (error.response.status === 401) {
					setServerError(t('error.invalid_link', { ns: 'reset_password' }));
				}
				if (error.response.status === 403) {
					setServerError(t('error.expired_link', { ns: 'reset_password' }));
					setActionButton(forgotten_password);
				}
			} else {
                setServerError(t('error.reset_password_error', { ns: 'reset_password' }));
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				}
            }
        }
    };
	
	return (<>
		<MetaTags />
		
		<h1>{ t('title', { ns: 'reset_password' }) }</h1>

		<form className="form-account" onSubmit={handleSubmit} noValidate>
		<div className="form-group">
                    <label htmlFor="password">{ t('form.password_label', { ns: 'user_form' }) }</label>
                    <div className="password-wrapper">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            required
                            minLength="10"
                            maxLength="40"
                            className={errors.password ? 'error' : ''}
                        />
                        <button
                            type="button"
                            className="password-toggle"
                            onClick={() => setShowPassword(prev => !prev)}
                        >
                            {showPassword ? <IoEyeOff /> : <IoEye />}
                        </button>
                    </div>
                    <ul className="password-criteria">
                        <li className={passwordCriteria.length ? 'valid' : 'invalid'}>
                            {passwordCriteria.length ? <IoCheckmark /> : <IoClose />}
                            { t('form.password_criteria_length', { ns: 'user_form' }) }
                        </li>
                        <li className={passwordCriteria.letter ? 'valid' : 'invalid'}>
                            {passwordCriteria.letter ? <IoCheckmark /> : <IoClose />}
                            { t('form.password_criteria_letter', { ns: 'user_form' }) }
                        </li>
                        <li className={passwordCriteria.number ? 'valid' : 'invalid'}>
                            {passwordCriteria.number ? <IoCheckmark /> : <IoClose />}
                            { t('form.password_criteria_number', { ns: 'user_form' }) }
                        </li>
                        <li className={passwordCriteria.specialChar ? 'valid' : 'invalid'}>
                            {passwordCriteria.specialChar ? <IoCheckmark /> : <IoClose />}
                            { t('form.password_criteria_special_char', { ns: 'user_form' }) }
                        </li>
                    </ul>
                    {errors.password && <p className="error">{errors.password}</p>}
                </div>

                <div className="form-group">
                    <label htmlFor="confirmPassword">{ t('form.confirm_password_label', { ns: 'user_form' }) }</label>
                    <div className="password-wrapper">
                        <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            required
                            className={errors.confirmPassword ? 'error' : ''}
                        />
                        <button
                            type="button"
                            className="password-toggle"
                            onClick={() => setShowConfirmPassword(prev => !prev)}
                        >
                            {showConfirmPassword ? <IoEyeOff /> : <IoEye />}
                        </button>
                    </div>
                    {errors.confirmPassword && <p className="error">{errors.confirmPassword}</p>}
                </div>

				<div id="submit-wrapper">
				<button type="submit">{ t('submit_button', { ns: 'reset_password' }) }</button>
				{serverError && <p className="error-message">{serverError}</p>}
				{requestSuccess && <p className="success-message">{ t('success', { ns: 'reset_password' }) }</p>}
				{actionButton}
			</div>
		</form>
	</>);
}

ResetPasswordPage.namespaces = ['reset_password', 'user_form'];

export default ResetPasswordPage;