import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { api } from '@client/shared';
import '@client/shared/src/stylesheets/form.css';

import SelectDropdownPanier from './SelectDropdownPanier';

import '../stylesheets/media.css';

function PanierBar({ code_image, user_id }) {
	const { t } = useTranslation(['media']);
	const [paniers, setPaniers] = useState([]);
	const [panier, setPanier] = useState(null);
	const [serverError, setServerError] = useState('');

	// Fetch user's list of Panier
	useEffect(() => {
		async function fetchPaniers() {
			try {
				const response = await api.get(`/partners/${user_id}/paniers_media/${code_image}`);

				// Handle the response
				if (response.status === 200) {
					const translatedPaniers = response.data.paniers.map(option => ({
						...option,
						nom_en: option.nom === "Favoris" ? "Favourites" : option.nom
					}));
					setPaniers(translatedPaniers);
				}
			} catch (error) {
				setServerError(t('error.fetch_paniers', { ns: 'media' }));
				if (error.response && error.response.data && error.response.data.message) {
					console.error(error.response.data.message);
				} else {
					console.error("Error while fetching user's list of Paniers:", error);
				}
			}
		}

		if (user_id) {
			fetchPaniers();
		}
		// eslint-disable-next-line
	}, []);

	const handleAddImage = async (e) => {
		e.preventDefault();  // Prevents form submission
		setServerError(false);

		// Prevents form submission if no panier is selected
		if (!panier) {
			return;
		}
		
		try {
			const panier_id = panier.panier_id;
			const response = await api.post(`/partners/${user_id}/paniers/${panier_id}`, {
				code_image: code_image
			});
			if (response.status === 200) {
				setPanier(null);

				// Filter out the panier from the paniers array
				const filtered = paniers.filter(option => option.panier_id !== panier_id);
				setPaniers(filtered);
			}
		} catch (error) {
			setServerError(t('error.add_image', { ns: 'media' }));
			if (error.response && error.response.data && error.response.data.message) {
				console.error(error.response.data.message);
			}
		}
	}

	return (<>
		<form id="add-image-panier" onSubmit={handleAddImage} noValidate>
			<label htmlFor="panier">{t('add_image_panier', { ns: 'media' })}</label>
			<div id="add-image-input">
				<SelectDropdownPanier
					id="panier"
					options={paniers}
					onChange={setPanier}
					value={panier}
					placeholder={t('champs.select', { ns: 'common' })}
				/>
				<button type="submit" id="add-image-button">{ t('add_image_button', { ns: 'media' }) }</button>
			</div>
			{serverError && <p className="error-message">{serverError}</p>}
		</form>
	</>);
}

export default PanierBar;