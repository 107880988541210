import { ConfirmModal } from './ConfirmModal';

export const SessionExpiryModal = ({ t = null, onConfirm, onCancel }) => {
    const useI18n = process.env.REACT_APP_USE_I18N === 'true';
	if (useI18n && !t) {
		return null;
	}

	return (
		<ConfirmModal
			message={ useI18n ?
				t('modal.refresh_session_message', { ns: 'common' }) :
				"Votre session est sur le point d'expirer. Voulez-vous la prolonger\u00A0?"}
			confirm={ useI18n ?
				t('modal.refresh', { ns: 'common' }) :
				"Prolonger" }
			cancel={ useI18n ?
				t('modal.logout', { ns: 'common' }) :
				"Déconnexion" }
			onConfirm={onConfirm}
			onCancel={onCancel}
		/>
	);
};