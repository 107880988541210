import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoClose, IoMenu } from 'react-icons/io5';

import { logoVDI_thumbnail, useAuthContext, useLogout, displayWidth } from '@client/shared';

import LocalizedLink from './LocalizedLink';
import SwitchLanguage from './SwitchLanguage';
import GoToFavourites from './GoToFavourites';
import SearchBar from './SearchBar';

function Header() {
	const [showMenu, setShowMenu] = useState(false);
	const { t } = useTranslation([]);
	const location = useLocation();
	const navigate = useNavigate();
	const { user } = useAuthContext();
	const { logout } = useLogout();
	
	const toggleMenu = () => {
		setShowMenu(!showMenu);
	};

	const closeMenuOnMobile = () => {
		if (window.innerWidth < displayWidth.medium && showMenu) {
			setShowMenu(false);
		}
	};

	const logoutHandler = () => {
		// code pour déconnecter utilisateur
		closeMenuOnMobile();
		logout();
	}

	// Automatically close the menu on mobile when the user clicks on a link
	useEffect(() => {
		closeMenuOnMobile();
		// eslint-disable-next-line
	}, [location.pathname, location.search, navigate]);

	const closeButton = (<>
		<div id="nav__close" onClick={toggleMenu}>
			<IoClose />
		</div>
	</>);

	const visitor_topnav = (<>
		{closeButton}
		<ul className='nav__list'>
			<li className='nav__item'><LocalizedLink to="/">{ t('header.link_home', { ns: 'common' }) }</LocalizedLink></li>
			<li className='nav__item'><LocalizedLink to="/compositions">{ t('header.link_compositions', { ns: 'common' }) }</LocalizedLink></li>
			<li className='nav__item'><LocalizedLink  to="/about">{ t('header.link_about', { ns: 'common' }) }</LocalizedLink></li>
			<li className='nav__item'><LocalizedLink  to="/books">{ t('header.link_books', { ns: 'common' }) }</LocalizedLink></li>
			<li className='nav__item'><LocalizedLink  to="/contact">{ t('header.link_contact', { ns: 'common' }) }</LocalizedLink></li>
		</ul>
		<ul className='nav__list'>
			<li className='nav__cta'><LocalizedLink to="/login" rel="nofollow">{ t('header.link_login', { ns: 'common' }) }</LocalizedLink></li>
		</ul>
	</>);

	const connected_topnav = (<>
		{closeButton}
		<ul className='nav__list'>
			<li className='nav__item'><LocalizedLink  to="/">{ t('header.link_home', { ns: 'common' }) }</LocalizedLink></li>
			<li className='nav__item'><LocalizedLink  to="/search">{ t('header.link_search', { ns: 'common' }) }</LocalizedLink></li>
			<li className='nav__item'><LocalizedLink  to="/compositions">{ t('header.link_compositions', { ns: 'common' }) }</LocalizedLink></li>
			<li className='nav__item'><LocalizedLink  to="/about">{ t('header.link_about', { ns: 'common' }) }</LocalizedLink></li>
			<li className='nav__item'><LocalizedLink  to="/books">{ t('header.link_books', { ns: 'common' }) }</LocalizedLink></li>
			<li className='nav__item'><LocalizedLink  to="/contact">{ t('header.link_contact', { ns: 'common' }) }</LocalizedLink></li>
			<li className='nav__item'><LocalizedLink  to="/account">{ t('header.link_account', { ns: 'common' }) }</LocalizedLink></li>
		</ul>
		<ul className='nav__list'>
			<li className='nav__cta'><LocalizedLink to="/" onClick={logoutHandler}>{ t('header.link_logout', { ns: 'common' }) }</LocalizedLink></li>
		</ul>
	</>);

	return (
		<header className="page-header">
			<div className="container">
				<div className="logo">
					<LocalizedLink  to="/"><img src={logoVDI_thumbnail} alt="Logo" /></LocalizedLink>
				</div>
				<div className="imagesguenomiller">
                    <p id="brand">{ t('header.brand', { ns: 'common' }) }</p>
                    <p id="subtitle">{ t('header.subtitle', { ns: 'common' }) }</p>
                </div>
				<div className="language-search">
					{ user ? <SearchBar /> : <></>}
					<div className="icons">
						<div className="language">
							<SwitchLanguage />
						</div>
						{ user ? <GoToFavourites /> : <></>}
					</div>
				</div>
                <nav className="topnav">
					<div className={`nav__menu ${showMenu ? "show-menu" : ""}`}>
						{ user ? connected_topnav : visitor_topnav}
					</div>
					<div id="nav__toggle" onClick={toggleMenu}>
						<IoMenu />
					</div>
                </nav>
			</div>
		</header>
	);
}

export default Header;