import { useTranslation } from 'react-i18next';

import { ConfirmModal } from '@client/shared';

const DeleteFavouritesModal = ({ onConfirm, onCancel }) => {
    const { t } = useTranslation();

	return (
		<ConfirmModal
			message={ t('modal.delete_favourites_message', { ns: 'common' }) }
			confirm={ t('modal.confirm', { ns: 'common' }) }
			cancel={ t('modal.cancel', { ns: 'common' }) }
			onConfirm={onConfirm}
			onCancel={onCancel}
		/>
	);
};

export default DeleteFavouritesModal;
